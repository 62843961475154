import Vue from 'vue';
import Vuex from 'vuex';
import * as types from './types';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        selectedEvent: {},
        loading: false,
        auth: {},
        tabs: [],
        tab: 0,
        drawer: false,
    },
    mutations: {
        [types.MUTATE_SET_EVENT]: (state, event) => {
            state.selectedEvent = event;
        },
        [types.MUTATE_SET_LOADING]: (state, loading) => {
            state.loading = loading;
        },
        [types.MUTATE_SET_AUTH]: (state, auth) => {
            state.auth = auth;
        },
        [types.MUTATE_SET_TABS]: (state, tabs) => {
            state.tabs = tabs;
            state.tab = 0;
        },
        [types.MUTATE_SET_TAB_NAVIGATION]: (state, tab) => {
            state.tab = tab;
        },
        [types.MUTATE_SET_DRAWER]: (state, drawer) => {
            state.drawer = drawer;
        }
    },
    actions: {
        [types.SET_EVENT]: ({ commit }, event) => {
            commit(types.MUTATE_SET_EVENT, event);
        },
        [types.SET_LOADING]: ({ commit }, loading) => {
            commit(types.MUTATE_SET_LOADING, loading);
        },
        [types.SET_AUTH]: ({ commit }, auth) => {
            commit(types.MUTATE_SET_AUTH, auth)
        },
        [types.SET_TABS]: ({ commit }, tabs) => {
            commit(types.MUTATE_SET_TABS, tabs);
        },
        [types.SET_TAB_NAVIGATION]: ({ commit }, tab) => {
            commit(types.MUTATE_SET_TAB_NAVIGATION, tab);
        },
        [types.SET_DRAWER]: ({ commit }, drawer) => {
            commit(types.MUTATE_SET_DRAWER, drawer);
        },
    },
})
