<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="users"
            disable-CustomPagination
            hide-default-footer
            :loading="loading"
            no-data-text="Geen gebruikers gevonden"
            class="elevation-1"
            :items-per-page="50"
        >
            <template #[`item.created_at`]="{ item }">
                {{ $formatDate(item.createdAt) }}
            </template>
            <template #[`item.verified`]="{ item }">
                <span v-if="item._id !== uid" @click="checkAuthId(item)">
                    <v-icon 
                        v-if="item.auth_id" 
                        style="color: green"
                        >
                        mdi-check
                    </v-icon>
                    <v-icon 
                        v-else 
                        style="color: red"
                        >
                        mdi-close
                    </v-icon>
                </span>
            </template>
            <template #[`item.admin`]="{ item }">
                <span v-if="item._id !== uid">
                    <v-btn 
                        v-if="item.administrator" 
                        icon
                        @click="setAdmin(item._id, false)"
                        >
                        <v-icon style="color: green">
                            mdi-check
                        </v-icon>
                    </v-btn>
                    
                    <v-btn
                        v-else 
                        icon
                        @click="setAdmin(item._id, true)"
                        >
                        <v-icon style="color: red">
                            mdi-close
                        </v-icon>
                    </v-btn>
                </span>
            </template>
            <template #[`item.delete`]="{ item }">
                    <v-btn 
                    icon
                    v-if="item._id !== uid"
                    @click="confirmDelete(item._id)"
                    :disabled="item.administrator"
                    >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>
            <template #[`item.details`]="{ item }">
                <v-btn
                    icon
                    @click="viewDetails(item._id)"
                    >
                    <v-icon>mdi-account-details</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <CustomPagination
            :page="filters.page"
            @page-changed="$emit('page-changed', $event)"
            :currentItems="users.length"
        ></CustomPagination>

        <!-- USER DETAILS -->
        <v-dialog
            v-model="userDialog"
            max-width="80vw"
            scrollable
            >   
            <v-card>
                <user-details 
                    :detailUser="detailUser"
                    @close="userDialog = false"
                    @refresh-user="detailUser = $event"
                    @refresh="$emit('refresh')"
                ></user-details>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CustomPagination from '@/components/Generic/CustomPagination.vue';
import UserDetails from '../users/UserDetails';

import { mapState } from 'vuex';
import userApi from '@/api/users';

export default {
    components: {
        CustomPagination,
        UserDetails
    },
    props: {
        filters: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        users: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            headers: [
                { text: 'E-mail', value: 'email' },
                { text: 'Voornaam', value: 'firstName' },
                { text: 'Achternaam', value: 'lastName' },
                { text: 'Aangemaakt', value: 'created_at' },
                { text: 'Geverifieerd', value: 'verified' },
                { text: 'Admin', value: 'admin' },
                { text: 'Verwijderen', value: 'delete' },
                { text: 'Details', value: 'details' }
            ],
            detailUser: {},
            userDialog: false,
        }
    },
    computed: {
        ...mapState({
            uid: state => state.auth._id
        })
    },
    methods: {
        async checkAuthId(user) {
            const response = await this.$swal.fire({
                title: 'Auth0 ID',
                text: `Huidige Auth0 ID: ${user.auth_id}`,
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                input: 'text',
                footer: 'Verander dit NIET tenzij je weet wat je doet.'
            });
            
            if (response.isConfirmed && response.value !== '') {
                try {
                    await userApi.updateUserAuth(user._id, response.value);
                    this.$emit('refresh');
                } catch (error) {
                    console.log(error);
                }
            }
        },
        viewDetails(id) {
            this.detailUser = this.users.find(x => x._id === id);
            this.userDialog = true;
        },
        async confirmDelete(id) {
            const result = await this.$swal.fire({
                title: 'Verwijderen?',
                text: `Dit kan niet ongedaan gemaakt worden.`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Annuleren'
            });

            if (result.value && result.isConfirmed) {
                try {
                    await userApi.deleteUser(id);
                    this.$emit('refresh');
                } catch (error) {
                    console.log(error);
                }
            }
        },
        async setAdmin(id, admin) {
            if (admin) {
                const result = await this.$swal.fire({
                    title: 'Gebruiker admin maken?',
                    text: `Deze gebruiker krijgt toegang tot alle pagina's`,
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: 'Annuleren'
                });

                if (!result.value) return;
            }

            try {
                await userApi.updateUserAdmin(id, admin);
                this.$emit('refresh');
            } catch (error) {
                console.log(error);
            }
        },
    }
} 
</script>
