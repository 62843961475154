<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-icon small v-bind="attrs" v-on="on" v-if="pictures === 'yes'">mdi-camera</v-icon>
            <v-icon small v-bind="attrs" v-on="on" v-else-if="pictures === 'onlydog'">mdi-dog-side</v-icon>
            <v-icon small v-bind="attrs" v-on="on" v-else>mdi-camera-off</v-icon>
        </template>
        <span v-if="pictures === 'yes'">Foto's toegelaten</span>
        <span v-else-if="pictures === 'onlydog'">Enkel foto's van hond</span>
        <span v-else>Foto's niet toegelaten</span>
    </v-tooltip>
</template>

<script>
export default {
    props: {
        pictures: {
            type: String,
            required: true
        }
    }
}
</script>