<template>
    <div class="admin-container">
        <v-row justify="center">
            <v-col sm=12 md=12 class="default-header">
                <h1>Events beheren</h1>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm=12>
                <FiltersBase
                    v-model="filters"
                    :filters="['future', 'date']"
                    @reset="filters = { future: true, page: 0 }"
                ></FiltersBase>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <EventsAdminList
                    :events="events"
                    @page-changed="filters.page = $event"
                    @refresh="getEvents"
                    @set-event="editEvent = $event"
                    @edit-event="handleCreateEvent"
                    :editEvent="editEvent"
                    :filters="filters"
                />
            </v-col>
        </v-row>

        <v-speed-dial
            v-model="fab"
            direction="top"
            fab
            bottom
            right
            fixed
            open-on-hover
        >
            <template v-slot:activator>
                <v-btn v-model="fab" fab color="red darken-1" large dark>
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else>mdi-chevron-up</v-icon>
                </v-btn>
            </template>
            <v-btn @click="getEvents" fab color="red darken-1" dark small>
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn @click="handleCreateEvent(null)" fab color="red darken-1" dark small>
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-speed-dial>

        <!-- ADD EVENT DIALOG -->
        <v-dialog v-model="eventDialog" max-width="100vw" width="800">
            <create-event 
                @submitted="createEventClosed" 
                @refresh="getEvents"
                :editEvent="editEvent"
            ></create-event>
        </v-dialog>

    </div>
</template>

<script>
import CreateEvent from './events/CreateEvent';
import EventsAdminList from './events/EventsAdminList.vue';
import FiltersBase from './components/FiltersBase.vue';


import eventApi from '@/api/events';

export default {
    name: 'EventAdmin',
    components: {
        CreateEvent,
        EventsAdminList,
        FiltersBase,
    },
    data() {
        return {
            events: [],
            editEvent: null,
            eventDialog: false,
            fab: false,
            filters: {
                page: 0,
                future: true,
                date: ''
            }
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            if (this.$route.query.id) {
                this.filters.id = this.$route.query.id;
                this.filters.future = false;
            }

            this.getEvents();
        },
        async getEvents() {
            try {
                const result = await eventApi.getEvents(this.filters);
                this.events = await result.json();
                if (this.editEvent) {
                    this.editEvent = this.events.find(event => event._id === this.editEvent._id);
                }
            } catch (error) {
                console.log(error);
            }
        },   
        handleCreateEvent(event) {
            this.editEvent = event;
            this.eventDialog = true;
        },
        createEventClosed() {
            this.eventDialog = false;
            this.editEvent = null;
            this.getEvents();
        },
    },
    watch: {
        filters: {
            handler() {
                this.getEvents();
            },
            deep: true
        },
    }
}
</script>