export const SET_EVENT = 'SET_EVENT';
export const SET_LOADING = 'SET_LOADING';
export const SET_AUTH = 'SET_AUTH';
export const SET_TABS = 'SET_TABS';
export const SET_TAB_NAVIGATION = 'SET_TAB_NAVIGATION';
export const SET_DRAWER = 'SET_DRAWER';

export const MUTATE_SET_EVENT = 'MUTATE_SET_EVENT';
export const MUTATE_SET_LOADING = 'MUTATE_SET_LOADING';
export const MUTATE_SET_AUTH = 'MUTATE_SET_AUTH';
export const MUTATE_SET_TABS = 'MUTATE_SET_TABS';
export const MUTATE_SET_TAB_NAVIGATION = 'SET_TAB_NAVIGATION';
export const MUTATE_SET_DRAWER = 'SET_DRAWER';