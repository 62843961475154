<template>
    <div v-if="showCookieNotice">
        <div class="cookie-notice">
            <p class="m-0">
                Deze website gebruikt cookies.
            </p>
            <div class="cookie-notice__buttons">
                <button class="cookie-notice__button cookie-notice__button--accept" @click="acceptCookieNotice">
                    OK
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showCookieNotice: localStorage.getItem('cookieNotice') === 'false' ? false : true,
        }
    },
    methods: {
        acceptCookieNotice() {
            localStorage.setItem('cookieNotice', false);
            this.showCookieNotice = false;
        }
    }
}
</script>
