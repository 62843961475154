import * as http from './main';

const api = {
    createUser: (user) => {
        return http.post('register', user);
    },

    confirmUser: (token) => {
        return http.get(`register`, { token: token });
    },

    getUser: () => {
        return http.get('user');
    },

    getUserById: (id) => {
        return http.get(`user/${id}`);
    },

    getAll: (query) => {
        return http.get('user/list', query);
    },

    updateUser: (user) => {
        return http.post('user', user);
    },

    updateUserAuth: (userId, authId) => {
        return http.post('user/auth', { userId, authId })
    },

    getSubscriptions: () => {
        return http.get('user/subscriptions');
    },

    checkValidSubscriptions: (id) => {
        return http.get(`user/validsubscription/${id}`);
    },

    saveSubscription: (userId, subscription) => {
        return http.post(`user/subscriptions/${userId}`, subscription);
    },

    addPresence: (userId, subId) => {
        return http.post(`user/add-presence`, {
            uid: userId,
            id: subId
        });
    },

    deleteUser: (id) => {
        return http.deleteCall(`user/${id}`);
    },

    updateUserAdmin: (id, admin) => {
        return http.put('user', { id, administrator: admin });
    },

    updateNotifications: (notifications) => {
        return http.post('user/notifications', { notifications });
    }
}

export default api;