<template>
    <v-card>
        <v-card-title>Factuur aanmaken</v-card-title>
        <v-card-text>
            <v-form ref="form">
                <v-row>
                    <v-col sm="12" md="6">
                        <v-text-field
                            label="Klantnaam"
                            v-model="invoice.client.name"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                        <v-text-field
                            label="BTW nummer"
                            v-model="invoice.client.vat"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col sm="12" md="6">
                        <v-text-field
                            label="Straat + nummer"
                            v-model="invoice.client.street"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                        <v-text-field
                            label="Postcode + gemeente"
                            v-model="invoice.client.address"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col sm="12" md="6">
                        <v-menu
                            ref="date"
                            :close-on-content-click="true"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="new Date(invoice.due).toLocaleDateString('nl-BE')"
                                    label="Te betalen tegen"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="invoice.due"
                                no-title
                                scrollable
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.date.save(invoice.due)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col sm="12" md="6">
                        <v-switch
                            v-model="invoice.paid"
                            :label="'Betaald?'"
                        ></v-switch>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col sm="12">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-left">Beschrijving</th>
                                    <th class="text-left">Hoeveelheid</th>
                                    <th class="text-left">Prijs per stuk</th>
                                    <th class="text-left">BTW</th>
                                    <th class="text-left">Prijs incl</th>
                                    <th class="text-left">Nota</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(invoiceLine, i) in invoice.lines" :key="i">
                                    <td>{{ invoiceLine.description }}</td>
                                    <td>{{ invoiceLine.amount }}</td>
                                    <td>€{{ invoiceLine.pricePer }}</td>
                                    <td>{{ invoiceLine.tax }}%</td>
                                    <td>€{{ +invoiceLine.priceIn }}</td>
                                    <td>{{ invoiceLine.comment }}</td>
                                    <td>
                                        <v-btn icon @click="removeLine(i)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <v-text-field
                                            placeholder="Beschrijving"
                                            dense
                                            v-model="newLine.description"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            placeholder="Hoeveelheid"
                                            type="number"
                                            dense
                                            v-model="newLine.amount"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            placeholder="Prijs per stuk"
                                            type="number"
                                            prefix="€"
                                            dense
                                            :value="newLine.pricePer"
                                            @input="handleNoTaxInput"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            placeholder="BTW"
                                            type="number"
                                            suffix="%"
                                            dense
                                            :value="newLine.tax"
                                            @input="changeTax"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            placeholder="Prijs inclusief"
                                            type="number"
                                            prefix="€"
                                            dense
                                            :value="newLine.priceIn"
                                            @input="handleTaxInput"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            placeholder="Nota"
                                            dense
                                            v-model="newLine.comment"
                                        ></v-text-field>
                                    </td>                                  
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <v-btn class="mt-2" @click="addLine">Lijn toevoegen</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select
                            :items="companies"
                            label="Bedrijf"
                            v-model="invoice.company"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field 
                            hint="Laat dit leeg om automatisch te genereren, best niet veranderen bij het aanpassen"
                            persistent-hint
                            label="Factuurnummer"
                            v-model="invoice.invoiceNumber"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            name="Nota"
                            label="Nota"
                            hint="Eventuele nota's zet je hier."
                            v-model="invoice.comment"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn color="primary" @click="handleSave">Opslaan</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="$emit('close')">Annuleren</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
const invoiceLine = {
    description: '',
    amount: 1,
    pricePer: 0,
    priceIn: 0,
    tax: 21,
    comment: ''
}

const initialData = {
    client: {
        name: null,
        street: null,
        address: null,
    },
    due: new Date(new Date().setDate(new Date().getDate() + 14)).toISOString(),
    paid: false,
    lines: [],
    comment: '',
    company: 'therapy4dogs'
}

export default {
    props: ['selectedInvoice'],
    data() {
        return {
            invoice: {
                client: {
                    name: null,
                    street: null,
                    address: null,
                },
                due: new Date(new Date().setDate(new Date().getDate() + 14)).toISOString(),
                paid: false,
                lines: [],
                comment: '',
            },
            errors: [],
            newLine: { ...invoiceLine },
            companies: [
                { text: 'Therapy4Dogs', value: 'therapy4dogs' },
                { text: 'SvBits', value: 'svbits' }
            ]
        }
    },
    methods: {
        handleSave() {
            this.$emit('save', this.invoice);
            this.invoice = { 
                ...initialData,
                client: { ...initialData.client }
            };
        },
        addLine() {
            this.invoice.lines = [ ...this.invoice.lines, this.newLine ];
            this.newLine = { ...invoiceLine };
        },
        removeLine(index) {
            this.invoice.lines.splice(index, 1);
        },
        handleNoTaxInput(val) {
            this.newLine.pricePer = val;
            this.newLine.priceIn = this.addTax(val, this.newLine.tax);
        },
        handleTaxInput(val) {
            this.newLine.priceIn = val;
            this.newLine.pricePer = this.removeTax(val, this.newLine.tax)
        },
        addTax(val, tax) {
            return (+val * +`1.${tax}`).toFixed(2);
        },
        removeTax(val, tax) {
            return (+val / `1.${tax}`).toFixed(2);
        },
        changeTax(val) {
            this.newLine.tax = val;
            this.newLine.priceIn = this.addTax(this.newLine.pricePer, this.newLine.tax);
        },
        checkSelectedInvoice() {
            if (this.selectedInvoice._id) {
                this.invoice = { ...this.selectedInvoice };
            } else if (this.selectedInvoice) {
                this.invoice = { ...initialData, ...this.selectedInvoice }
            } else {
                this.invoice = { 
                    ...initialData,
                    client: { ...initialData.client }
                };
            }
        },
    },
    mounted() {
        this.checkSelectedInvoice();
    },
    watch: {
        selectedInvoice: {
            handler(val) {
                if (val) this.checkSelectedInvoice();
                else this.invoice = { ...initialData };
            }
        }
    }
}
</script>