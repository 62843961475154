<template>
    <div>
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="value"
                    :label="label"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
            </template>
            <v-time-picker
                v-if="menu"
                v-model="time"
                full-width
                @click:minute="$refs.menu.save(time)"
                format="24hr"
            ></v-time-picker>
        </v-menu>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true,
            default: ''
        },
        label: {
            type: String,
            required: false,
            default: 'Kies een tijd'
        }
    },
    data() {
        return {
            menu: false,
            time: this.value
        }
    },
    watch: {
        time: {
            handler(val) {
                this.$emit('input', val);
            }
        }
    }
}
</script>