<template>
    <div class="admin-container">
        <v-row justify="center">
            <v-col sm="12" md="12" class="default-header">
                <h1>Gebruikers beheren</h1>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm=12>
                <FiltersBase 
                    v-model="filters"
                    :filters="['firstName', 'lastName', 'email', 'id', 'pictures']"
                    @reset="resetFilters"
                />
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-col>
                <UserList 
                    :filters="filters"
                    :users="users"
                    :loading="loading"
                    @refresh="getUsers"
                    @page-changed="filters.page = $event"
                />
            </v-col>
        </v-row>

        <!-- FAB -->
        <v-speed-dial
            v-model="fab"
            direction="top"
            fab
            bottom
            right
            fixed
            open-on-hover
        >
            <template v-slot:activator>
                <v-btn v-model="fab" fab color="red darken-1" large dark>
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else>mdi-chevron-up</v-icon>
                </v-btn>
            </template>
            <v-btn @click="getUsers" fab color="red darken-1" small dark>
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </v-speed-dial>
    </div>
</template>

<script>
import UserList from './users/UserList.vue';
import FiltersBase from './components/FiltersBase.vue';

import userApi from '@/api/users';

export default {
    name: 'UserAdmin',
    components: {
        FiltersBase,
        UserList,
    },
    data() {
        return {
            headers: [
                { text: 'E-mail', value: 'email' },
                { text: 'Voornaam', value: 'firstName' },
                { text: 'Achternaam', value: 'lastName' },
                { text: 'Aangemaakt', value: 'created_at' },
                { text: 'Geverifieerd', value: 'verified' },
                { text: 'Admin', value: 'admin' },
                { text: 'Verwijderen', value: 'delete' },
                { text: 'Details', value: 'details' }
            ],
            users: [],
            fab: false,
            filters: {
                page: 0,
                firstName: '',
                lastName: '',
                email: '',
                id: '',
                pictures: '',
            },
            loading: true,
        }
    },
    
    created() {
        if (this.$route.query.uid) this.filters.id = this.$route.query.uid;
        this.getUsers();
    },
    methods: {
        async getUsers() {
            this.loading = true;

            try {
                const result = await userApi.getAll(this.filters);
                this.users = await result.json();
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        resetFilters() {
            this.filters = {
                page: 0,
                firstName: '',
                lastName: '',
                email: '',
                id: '',
                pictures: ''
            };
        }
    },
    watch: {
        filters: {
            handler() {
                this.getUsers();
            },
            deep: true,
        }
    }
}
</script>
