import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import dateTimeFormatter from './utils/dateTimeFormatter';
import translateFilter from './utils/translateFilters';
import plusCode from './utils/mapsPlusCode';
import VueHtmlToPaper from 'vue-html-to-paper';
import VueGtag from "vue-gtag";
import VueSweetalert2 from 'vue-sweetalert2';

// Import the Auth0 configuration
import * as auth from "./auth_config.json";

// Import the plugin here
import { Auth0Plugin } from './plugins/auth';

Vue.use(Auth0Plugin, {
  ...auth,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

const options = {
    name: '_blank',
    specs: [
      'fullscreen=yes',
      'titlebar=no',
      'scrollbars=no'
    ],
    styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900',
        'https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css',
        'https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css',
    ]
}

Vue.use(VueHtmlToPaper, options);
Vue.use(VueGtag, {
    config: { id: "UA-132657267-6" }
}, router);

import 'sweetalert2/dist/sweetalert2.min.css';
import './assets/scss/application.scss';

Vue.config.productionTip = false

Vue.use(dateTimeFormatter);
Vue.use(VueSweetalert2);
Vue.use(translateFilter);
Vue.use(plusCode);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
