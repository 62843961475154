<template>
    <div>
        <v-card v-if="selectedLesson">
            <v-card-title>{{ selectedLesson.name }} ({{ $formatDate(selectedLesson.date)}} - {{ $formatTime(selectedLesson.date )}})</v-card-title>
            <v-card-text>
                <v-list dense>
                    <v-subheader>Inschrijvingen ({{ selectedLesson.people.length + selectedLesson.registrations.length }}/{{ selectedLesson.maxPeople }})</v-subheader>
                    <v-list-item-group color="primary">
                        <v-list-item
                            v-for="(reg, j) in selectedLesson.registrations"
                            :key="`usr${j}`"
                            disabled
                            class="p-0 px-1"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-pencil</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="reg"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            v-for="(person, i) in selectedLesson.people"
                            :key="`${i}${person._id}`"
                            class="p-0"
                        >
                            <v-list-item-avatar @click="selectUser(person)">
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    :size="20"
                                    v-if="checkSub[person._id] == undefined"
                                ></v-progress-circular>
                                <v-icon class="text-success p-0" v-else-if="checkSub[person._id] == 1">mdi-check-circle</v-icon>
                                <v-icon class="text-danger p-0" v-else-if="checkSub[person._id] == 0">mdi-close-circle</v-icon>
                                <v-icon class="text-warning p-0" v-else-if="checkSub[person._id] == 2">mdi-alert-circle</v-icon>
                                <v-icon class="text-info p-0" v-else>mdi-help-circle</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ `${person.firstName} ${person.lastName} (${person.email})` }}
                                </v-list-item-title>                                
                            </v-list-item-content>
                            <v-list-item-action>
                                <div class="d-flex gap-1">
                                    <PicturesIndicator :pictures="person.pictures" />
                                    <v-btn icon>
                                        <v-icon @click="handleDelete(selectedLesson, person)">mdi-delete</v-icon>
                                    </v-btn>
                                </div>
                                
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="error" @click="$emit('close-dialog')">Sluiten</v-btn>
            </v-card-actions>
        </v-card>


        <!-- USER DIALOG -->
        <v-dialog
            v-model="userDialog"
            max-width="80vw"
            scrollable
            >   
            <v-card>
                <user-details 
                    :detailUser="detailUser"
                    :openedTabs=[2]
                    @close="userDialog = false"
                ></user-details>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import UserDetails from '@/components/Admin/users/UserDetails';
import PicturesIndicator from './PicturesIndicator';

import userApi from '@/api/users';

export default {
    components: {
        UserDetails,
        PicturesIndicator,
    },
    props: {
        selectedLesson: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            userDialog: false,
            detailUser: {},
            checkSub: {}
        }
    },
    mounted() {
        this.checkValidSubscriptions();
    },
    methods: {
        async handleDelete(lesson, person) {
            const result = await this.$swal.fire({
                title: 'Uitschrijven?',
                text: `Deze persoon (${person.firstName} ${person.lastName}) wordt uitgeschreven.`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Annuleren'
            });

            if (result.value) {
                this.$emit('remove-register', { lesson, person });
            }
        },
        async selectUser(person) {
            try {
                const response = await userApi.getUserById(person._id);
                const user = await response.json();

                this.detailUser = user;
                this.userDialog = true;
            } catch (error) {
                console.log(error);
            }
        },
        checkValidSubscriptions() {
            this.selectedLesson.people.forEach(async (user) => {
                try {
                    const response = await userApi.checkValidSubscriptions(user._id);
                    const result = await response.json();
                    this.checkSub[user._id] = result.valid;
                    this.$forceUpdate();
                } catch (error) {
                    console.log(error);
                }
            });
        }
    },
    watch: {
        selectedLesson: {
            handler(val) {
                if (val) {
                    this.checkSub = {};
                    this.checkValidSubscriptions();
                }
            }
        }
    }
}
</script>