<template>
    <div>
        <v-dialog
            :value="dialog"
            @click:outside="$emit('close')"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                    dark
                    color="primary"
                    >
                    <v-btn
                        icon
                        dark
                        @click="$emit('close')"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                            dark
                            text
                            @click="print"
                        >
                            Afdrukken
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <div id="printMe">
                    <div style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            align-items: center;
                            background: white;
                            margin: 0 auto;
                            margin-bottom: 0.5cm;
                            padding: 0 .5rem;
                            font-size: 2em;
                        ">
                        <div 
                            v-for="(participant, i) in participants" 
                            :key="participant._id" 
                            cols="4"
                            style="
                                border: 1px solid black;
                                padding: .8rem;
                                margin: 10px 0;
                                margin-right: 5px;
                                min-width: calc(50% - 5px);
                                max-width: calc(50% - 5px);
                                overflow: hidden;
                                border-radius: 5px;
                            "
                            >
                            <div class=row>
                                <div class="d-flex justify-content-start align-items-center col-2">
                                    <h1 class="pl-2" style="font-size: 1.5em">{{ participant.startNumber }}</h1>
                                </div>
                                <div class="col-10">
                                    <h4 
                                        class="mb-0" 
                                        style="
                                            font-size: 1em; 
                                            text-overflow: ellipsis;
                                            white-space: nowrap;
                                        ">
                                        <strong>{{ participant.name }}</strong>
                                    </h4>
                                    <p style="font-size: .6em" class="text--disabled mb-2">{{ participant.team || '(geen team)' }}</p>
                                    <p>
                                        <v-icon class="mr-1" style="font-size: .8em">mdi-dog</v-icon>
                                        {{ participant.dog.name }}
                                    </p>
                                    <h4>{{ participant.startTime }}</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col d-flex justify-content-end align-items-center p-0 pb-2 pr-4">
                                    <span class="text--disabled" style="font-size: .6rem">Therapy4Dogs - {{ dogSurvival.title }}</span>
                                </div>
                            </div>
                            <div class="row mt-4" style="page-break-before: always;" v-if="i > 0 && i % 8 === 0"></div>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        participants: {
            type: Array,
            required: true
        },
        dogSurvival: {
            type: Object,
            required: true
        },
    },
    methods: {
        print() {
            this.$htmlToPaper('printMe');
        },
    }
}
</script>
