const translations = {
    future: 'Toekomst',
    date: 'Datum',
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    email: 'E-mail',
    photos: `Foto's`,
    id: 'ID',
    mollie_id: 'Mollie ID',
    type: 'Type',
    invoiceNumber: 'Factuurnummer',
    unpaid: 'Onbetaald'
}

export const getTranslation = (key) => {
    if (translations[key]) return translations[key];
    return key;
}

const translateFilter = {
    install: (Vue) => {
        Vue.prototype.$translateFilter = (key) => getTranslation(key);
    }
}

export default translateFilter;