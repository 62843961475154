<template>
    <div class="pa-2">
        <v-card tile flat>
            <v-card-title v-if="detailUser">{{ detailUser.firstName }} {{ detailUser.lastName }}</v-card-title>

            <v-card-text>
                <v-expansion-panels multiple :value="openedTabs">
                    <UserDetailPanel :detailUser="detailUser" />
                    <DogsDetailPanel :detailUser="detailUser" />
                    <SubscriptionsDetailPanel 
                        :detailUser="detailUser"
                        @add-present="addPresent($event)"
                        @open-subscription-dialog="openSubscriptionDialog($event)"
                        @delete-subscription="deleteSubscription($event)"
                    />
                </v-expansion-panels>                
            </v-card-text>

            <v-card-actions>
                <v-btn color="primary" @click="openSubscriptionDialog(null)">Abonnement toevoegen</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="$emit('close')">Sluiten</v-btn>
            </v-card-actions>
        </v-card>

        <!-- EDIT SUBSCRIPTION DIALOG -->
        <v-dialog v-model="subscriptionDialog" max-width="100vw" width="800">
            <v-card class="mb-2">
                <v-card-title>Abonnement kiezen</v-card-title>
                <v-card-text>
                    <v-select
                        label="Baseer je abonnement op een bestaand abonnement"
                        :items="subscriptions"
                        item-text="title"
                        item-value="_id"
                        @change="handleSelectSub"
                    ></v-select>
                </v-card-text>
            </v-card>
            
            <create-subscription 
                :edit="true"
                :uid="detailUser._id"
                :subscription="editSubscription"
                @close-dialog="subscriptionDialog = false"
                @create-subscription="saveSubscription"
            ></create-subscription>
        </v-dialog>
    </div>
</template>

<script>
import CreateSubscription from '../components/CreateSubscription';

import UserDetailPanel from './UserDetailPanel.vue';
import DogsDetailPanel from './DogsDetailPanel.vue';
import SubscriptionsDetailPanel from './SubscriptionsDetailPanel.vue';

import subApi from '@/api/subscriptions';
import userApi from '@/api/users';

const cleanSub = {
    title: '',
    duration: 0,
    start: new Date().toISOString(),
    description: '',
    price: 0,
    turns: 0,
}

export default {
    components: {
        CreateSubscription,
        UserDetailPanel,
        DogsDetailPanel,
        SubscriptionsDetailPanel,
    },
    props: {
        detailUser: {
            type: Object,
            required: true
        },
        openedTabs: {
            type: Array,
            required: false,
            default: () => [0]
        }
    },
    data() {
        return {
            editSubscription: {},
            subscriptionDialog: false,
            subscriptions: []
        }
    },
    methods: {
        openSubscriptionDialog(sub) {
            this.getSubscriptions();
            if (sub) this.editSubscription = sub;
            else this.editSubscription = { ...cleanSub};
            this.subscriptionDialog = true;
        },
        async getSubscriptions() {
            try {
                const response = await subApi.getSubscriptions();
                this.subscriptions = await response.json();
            } catch (error) {
                console.log(error);
            }
        },
        handleSelectSub(id) {
            const selectedSub = this.subscriptions.find(x => x._id === id);
            this.editSubscription = {
                title: selectedSub.title,
                duration: selectedSub.duration,
                start: new Date().toISOString(),
                description: selectedSub.description,
                price: selectedSub.price,
                turns: selectedSub.turns,
            }
        },
        async saveSubscription() {
            const body = {
                subscription: { ...this.editSubscription }
            }
            
            try {
                const response = await userApi.saveSubscription(this.detailUser._id, body);
                const user = await response.json();
                this.subscriptionDialog = false;
                this.$emit('refresh-user', user);
            } catch (error) {
                console.log(error);
            }
        },
        async addPresent(sub) {
            try {
                const response = await userApi.addPresence(this.detailUser._id, sub._id);
                const subscription = await response.json();
                const index = this.detailUser.subscriptions.findIndex(x => x._id === subscription._id);
                this.detailUser.subscriptions.splice(index, 1, subscription);
            } catch (error) {
                console.log(error);
            }
        },
        async deleteSubscription(regId) {
            const result = await this.$swal.fire({
                title: 'Verwijderen?',
                text: `Dit kan niet ongedaan gemaakt worden.`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Annuleren'
            });

            if (result.isConfirmed) {
                try {
                    const response = await subApi.deleteUserSubscription(this.detailUser._id, regId);
                    const user = await response.json();
                    this.$emit('refresh-user', user);
                    this.$emit('refresh');
                } catch (error) {
                    console.log(error);
                }
            }
        },
        invertSubscriptions(subscriptions) {
            return subscriptions.reverse();
        }
    },
}
</script>
