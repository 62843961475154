<template>
    <div>
        <v-app-bar
            app
            color="blue darken-2"
            dark
            elevate-on-scroll
            >
            <v-app-bar-nav-icon @click="toggleDrawer" class="ml-1" v-if="$route.name !== 'dogsurvival'">
                <v-icon>mdi-menu</v-icon>
                Menu
            </v-app-bar-nav-icon>

            <v-btn class="ml-4" text @click="handleNewWebsiteClick">Test de nieuwe website</v-btn>

            <v-spacer></v-spacer>

            <!-- GLOBAL LOADING INDICATOR -->
            <div class="d-flex position-relative justify-content-center align-items-center">
                <v-progress-circular
                    class="position-absolute"
                    v-if="loading"
                    indeterminate
                    color="white"
                ></v-progress-circular>
                <img 
                    src="../../assets/img/logo.jpeg" 
                    class="elevation-4 header__logo header__logo--hover"
                    :class="loading ? 'header__logo--loading' : ''"
                    @click="$router.push('/')"
                />
            </div>

            <v-spacer></v-spacer>

            <!-- ADMIN OPTIONS -->
            <div v-if="$route.name !== 'dogsurvival'">
                <v-btn v-if="$auth.isAuthenticated" @click="logout" color="primary" depressed>
                    Uitloggen
                </v-btn>
                <div v-else class="d-flex">
                    <v-btn @click="handleRegister" color="error" class="mr-2" depressed>
                        Registreren
                    </v-btn>
                    <v-btn @click="login" color="primary" depressed>
                        Inloggen
                    </v-btn>
                </div>
                
            </div>

            <!-- ADDITIONAL NAVIGATION WHEN NECESSARY -->
            <template v-slot:extension v-if="tabs.length > 0">
                <v-tabs
                    :value="tab"
                    @change="handleTabChange"
                    background-color="blue darken-4"
                    :show-arrows="false"
                    >
                    <v-tabs-slider color="yellow"></v-tabs-slider>

                    <v-tab v-for="tab in tabs" :key="tab">
                        {{ tab }}
                    </v-tab>
                </v-tabs>
            </template>
        </v-app-bar>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import * as types from '../../store/types';
import { getHost } from '@/plugins/host';

export default {
    data() {
        return {
            admin: false
        }
    },
    computed: {
        ...mapState({
            loading: state => state.loading,
            tabs: state => state.tabs,
            tab: state => state.tab,
            drawer: state => state.drawer
        })
    },
    methods: {
        async logout() {
            await this.$store.dispatch(types.SET_AUTH, {});
            this.$auth.logout({
                returnTo: window.location.origin
            });
            
        },
        login() {
            this.$auth.loginWithRedirect();
        },
        toggleDrawer() {
            this.$store.dispatch(types.SET_DRAWER, !this.drawer);
        },
        handleTabChange(e) {
            this.$store.dispatch(types.SET_TAB_NAVIGATION, e);
        },
        async handleRegister() {
            this.$auth.loginWithRedirect({
                redirect_uri: `${getHost()}/registered`,
                screen_hint: 'signup',
            });
        },
        handleNewWebsiteClick() {
            window.location.href = 'https://inschrijvingen2.therapy4dogs.be';
        }
    },
    watch: {
        tab: {
            handler(val) {
                this.$store.dispatch(types.SET_TAB_NAVIGATION, val);
            }
        },
    },
}
</script>
