import * as http from './main';

const api = {
    getMyEvents: (future) => {
        return http.get(`events/my-events`, { future });
    },

    getEvent: (id) => {
        return http.get(`events/${id}`);
    },

    getAvailableEvents: (query) => {
        return http.get('events', query);
    },
    
    getEvents: (query) => {
        return http.get('events/registrations', query);
    },

    deleteEvent: (id) => {
        return http.deleteCall(`events/${id}`);
    },

    createEvent:(event) => {
        return http.post('events', event);
    },

    updateEvent: (event) => {
        return http.put(`events`, event);
    },
}

export default api;