<template>
    <div class="app_loader">
        <div class="d-flex position-relative justify-content-center align-items-center">
            <img 
                src="../../assets/img/logo.jpeg" 
                class="elevation-4 header__logo header__logo--loading"
            />
        </div> 
        <p class="mt-6">De app wordt geladen...</p>
    </div>
</template>

<script>
export default {

}
</script>