<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            Honden
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-left">
                            Naam
                        </th>
                        <th class="text-left">
                            Ras
                        </th>
                        <th class="text-left">
                            Geslacht
                        </th>
                        <th class="text-left">
                            Geboortedatum
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="dog in detailUser.dogs"
                    :key="dog._id"
                    >
                        <td>{{ dog.name }}</td>
                        <td>{{ dog.breed }}</td>
                        <td>{{ dog.gender }}</td>
                        <td>{{ new Date(dog.birthDate).toLocaleDateString() }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
    props: {
        detailUser: {
            type: Object,
            required: true,
        }
    }
}
</script>
