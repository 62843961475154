<template>
    <v-dialog v-model="localValue" @click:outside="localValue = false" max-width="90vw" width="600px">
        <v-card>
            <v-card-title>Deelnemer bewerken</v-card-title>
            <v-card-text>
                <v-form ref="dogsurvival-participant-form" @submit.prevent>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="participant.name"
                                label="Naam"
                                required
                                :rules="validRule"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="participant.email"
                                label="Email"
                                required
                                :rules="validRule"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="participant.team"
                                label="Team"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="participant.speed"
                                :items="speedOptions"
                                label="Snelheid"
                                required
                                :rules="validRule"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h5>Hond</h5>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="participant.dog.name"
                                label="Naam hond"
                                required
                                :rules="validRule"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="participant.dog.age"
                                type="Number"
                                label="Leeftijd hond"
                                required
                                :rules="validRule"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="participant.dog.breed"
                                label="Ras"
                                required
                                :rules="validRule"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h5>Optioneel</h5>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="participant.startTime"
                                label="Startuur"
                                type="time"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="participant.startNumber"
                                label="Startnummer"
                                type="number"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-checkbox
                                v-model="participant.paid"
                                label="Betaald"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" type="submit" @click="handleSubmit">Opslaan</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import dogsurvivalApi from '@/api/dogsurvival';

const newParticipant = {
    name: '',
    email: '',
    team: '',
    dog: {
        name: '',
        breed: '',
        age: 0,
    },
    paid: false,
    speed: 'walking',
    startTime: '',
    startNumber: 0,
}

export default {
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false,
        },
        selectedParticipant: {
            type: Object,
            required: false,
            default: () => {}
        },
        dogSurvivalId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            participant: structuredClone(newParticipant),
            validRule: [
                v => !!v || 'Dit veld is verplicht'
            ],
            speedOptions: [
                { text: 'Wandelend', value: 'walking' },
                { text: 'Rennend', value: 'running' },
                { text: 'Beide', value: 'combination' },
            ]
        };
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
                this.handleClose();
            }
        },
    },
    methods: {
        async handleSubmit() {
            if (!this.$refs['dogsurvival-participant-form'].validate()) return;
            await this.saveParticipant();
            this.handleClose();
        },
        async saveParticipant() {
            try {
                await dogsurvivalApi.updateParticipant(this.dogSurvivalId, this.participant._id, this.participant);
                this.$emit('saved');
            } catch (error) {
                console.log(error);
            }
        },
        handleClose() {
            this.participant = structuredClone(newParticipant);
            this.$emit('close');
        },
    },
    watch: {
        selectedParticipant: {
            handler() {
                this.participant = {
                    ...structuredClone(newParticipant),
                    ...structuredClone(this.selectedParticipant)
                };
            },
            deep: true,
        }
    }
}
</script>
