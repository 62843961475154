<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h1>Welkom terug, {{ auth.firstName }}</h1>
            </div>
            <div class="col-12">
                We hebben wat relevante informatie gebundeld voor jou.
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-6">
                <v-card>
                    <v-img
                        class="white--text align-end title__gradient"
                        height="150"
                        src="../../assets/img/dashboard/dogschool.jpg"
                    >
                        <v-card-title class="title__gradient">Jouw ingeschreven lessen</v-card-title>
                    </v-img>
                    
                    <v-card-text>
                        <table class="table">
                            <thead>
                                <tr>
                                    <td>Les</td>
                                    <td>Plaats</td>
                                    <td>Tijdstip</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="lesson in lessons" :key="lesson._id">
                                    <td>{{ lesson.title }}</td>
                                    <td>{{ lesson.location }}</td>
                                    <td>
                                        {{ $formatDate(lesson.date) }}
                                        <template v-if="formatRelativeDate(lesson.date) !== ''">({{ formatRelativeDate(lesson.date) }})</template>  
                                         - {{ $formatTime(lesson.date) }}
                                    </td>
                                </tr>
                                <tr v-if="lessons.length === 0">
                                    <td colspan="4">Geen ingeschreven lessen gevonden 😕</td>
                                </tr>
                            </tbody>
                        </table>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text color="primary" @click="$router.push({ name: 'profile', query: { tab: 3 }})">Bekijk alles</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text @click="$router.push('dogschool')">Naar kalender</v-btn>
                    </v-card-actions>
                </v-card>
                
            </div>
            <div class="col-12 col-lg-6">
                <v-card>
                    <v-img
                        class="white--text align-end"
                        height="150"
                        src="../../assets/img/dashboard/subscription.jpg"
                    >
                        <v-card-title class="title__gradient">Jouw abonnement</v-card-title>
                    </v-img>
                    <v-card-text>
                        <table class="table">
                            <thead>
                                <tr>
                                    <td>Naam</td>
                                    <td>Beurten</td>
                                    <td>Geldig tot</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="sub in subscriptions" :key="sub._id">
                                    <td>{{ sub.title }}</td>
                                    <td>{{ sub.turns > -1 ? sub.turns : '' }}</td>
                                    <td>{{ subscriptionDuration(sub) }}</td>
                                </tr>
                                <tr v-if="subscriptions.length === 0">
                                    <td colspan="4">Geen abonnementen gevonden 😕</td>
                                </tr>
                            </tbody>
                        </table>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text color="primary" @click="$router.push({ name: 'profile', query: { tab: 1 }})">Bekijk alles</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text @click="$router.push('subscriptions')">Naar abonnementen</v-btn>
                    </v-card-actions>
                </v-card>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-6">
                <v-card>
                    <v-img
                        class="white--text align-end"
                        height="150"
                        src="../../assets/img/dashboard/dogs.jpg"
                    >
                        <v-card-title class="title__gradient">Jouw honden</v-card-title>
                    </v-img>
                    <v-card-text>
                        <table class="table">
                            <thead>
                                <tr>
                                    <td>Naam</td>
                                    <td>Ras</td>
                                    <td class="text-right">Geboortedatum</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="dog in auth.dogs" :key="dog._id">
                                    <td>{{ dog.name }}</td>
                                    <td>{{ dog.breed }}</td>
                                    <td class="text-right">
                                        <span v-if="checkBirthday(dog.birthDate)">🎉</span>
                                        {{ $formatDate(dog.birthDate) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text color="primary" @click="$router.push('profile')">Naar profiel</v-btn>
                    </v-card-actions>
                </v-card>
            </div>
            <div class="col-12 col-lg-6">
                <v-card>
                    <v-img
                        class="white--text align-end"
                        height="150"
                        src="../../assets/img/dashboard/events.jpg"
                    >
                        <v-card-title class="title__gradient">Jouw ingeschreven events</v-card-title>
                    </v-img>
                    <v-card-text>
                        <table class="table">
                            <thead>
                                <tr>
                                    <td>Naam</td>
                                    <td>Plaats</td>
                                    <td>Tijdstip</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="event in events" :key="event._id">
                                    <td>{{ event.name }}</td>
                                    <td>{{ event.location }}</td>
                                    <td>
                                        {{ $formatDate(event.start) }}
                                        <template v-if="formatRelativeDate(event.start) !== ''">({{ formatRelativeDate(event.start) }})</template>  
                                         - {{ $formatTime(event.start) }}
                                    </td>
                                </tr>
                                <tr v-if="events.length === 0">
                                    <td colspan="4">Geen ingeschreven events gevonden 😕</td>
                                </tr>
                            </tbody>
                        </table>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text color="primary" @click="$router.push({ name: 'profile', query: { tab: 2 }})">Bekijk alles</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text @click="$router.push('events')">Naar events</v-btn>
                    </v-card-actions>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import lessonApi from '@/api/lessons';
import userApi from '@/api/users';
import eventApi from '@/api/events';

export default {
    data() {
        return {
            lessons: [],
            subscriptions: [],
            events: []
        }
    },
    computed: {
        ...mapState({
            auth: state => state.auth
        })
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.getLessons();
            this.getUserSubscriptions();
            this.getUserEvents();
        },
        checkBirthday(date) {
            const now = new Date();
            const birthdate = new Date(date);

            return now.getMonth() === birthdate.getMonth() && now.getDate() === birthdate.getDate();
        },
        async getLessons() {
            try {
                const response = await lessonApi.getMy();
                const allLessons = await response.json();
                this.lessons = allLessons.slice(0, 3);
            } catch (error) {
                console.log(error);
            } 
        },
        async getUserSubscriptions() {
            try {
                const response = await userApi.getSubscriptions();
                const allSubscriptions = await response.json();
                this.subscriptions = allSubscriptions.slice(0, 3);
            } catch (error) {
                console.log(error);
            }
        },
        subscriptionDuration(sub) {
            const start = new Date(sub.start);
            const duration = new Date(start.setMonth(start.getMonth() + sub.duration));

            return this.$formatDate(duration);
        },
        formatRelativeDate(date) {
            const eventDate = new Date(date);
            const now = new Date();

            const difference = eventDate.getTime() - now.getTime();
            const totalDays = Math.round(difference / (1000 * 3600 * 24));

            if (totalDays > 7) return '';

            const formatter = new Intl.RelativeTimeFormat('nl-BE', {
                numeric: 'auto',
                style: 'long'
            });

            return formatter.format(totalDays, 'day')
        },
        async getUserEvents() {
            try {
                const response = await eventApi.getMyEvents(true);
                const events = await response.json();
                this.events = events.slice(0, 3);
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>
