<template>
    <div class="admin-container">
        <v-row>
            <v-col sm=12 class="default-header">
                <h1>Betalingen beheren</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm=12>
                <FiltersBase 
                    v-model="filters"
                    :filters="['date', 'mollie_id']"
                    @reset="resetFilters"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col sm=12>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">ID</th>
                                <th class="text-left">Bedrag</th>
                                <th class="text-left">Beschrijving</th>
                                <th class="text-left">Status</th>
                                <th class="text-left">Aangemaakt</th>
                                <th class="text-left">Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="payment in payments" :key="payment._id">
                                <td>{{ payment.id }}</td>
                                <td>{{ payment.amount ? `${payment.amount.value} ${payment.amount.currency}` : '' }}</td>
                                <td>{{ payment.description }}</td>
                                <td>
                                    <v-icon class="payment-icon" :class="iconClass(payment.status)">{{ paymentIcon(payment.status) }}</v-icon>
                                    {{ status(payment.status) }}
                                </td>
                                <td>{{ payment.createdAt ? new Date(payment.createdAt).toLocaleString() : '' }}</td>
                                <td class="text-nowrap">
                                    <v-icon @click="viewDetails(payment.redirectUrl)">mdi-link</v-icon>
                                    <v-icon 
                                        v-if="payment.metadata && payment.metadata.uid" 
                                        @click="$router.push({ name: 'useradmin', query: { uid: payment.metadata.uid } })"
                                    >mdi-account</v-icon>
                                    <v-icon 
                                        v-else-if="payment.metadata && payment.metadata.registration" 
                                        @click="$router.push({ name: 'useradmin', query: { uid: payment.metadata.registration.user } })"
                                    >mdi-account</v-icon>
                                    <v-icon 
                                        v-if="payment.metadata && payment.metadata.registration" 
                                        @click="$router.push({ name: 'eventadmin', query: { id: payment.metadata.eventId } })"
                                    >mdi-calendar</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <CustomPagination
                    :page="filters.page"
                    @page-changed="filters.page = $event"
                    :currentItems="payments.length"
                ></CustomPagination>
            </v-col>
        </v-row>

        <!-- FAB -->
        <v-speed-dial
            v-model="fab"
            direction="top"
            fab
            bottom
            right
            fixed
            open-on-hover
        >
            <template v-slot:activator>
                <v-btn v-model="fab" fab color="red darken-1" large dark>
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else>mdi-chevron-up</v-icon>
                </v-btn>
            </template>
            <v-btn @click="getPayments(null)" fab color="red darken-1" small dark>
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </v-speed-dial>
    </div>
</template>

<script>
import CustomPagination from '@/components/Generic/CustomPagination.vue';
import FiltersBase from './components/FiltersBase.vue';

import paymentApi from '@/api/payments';

export default {
    components: {
        CustomPagination,
        FiltersBase,
    },
    data() {
        return {
            payments: [],
            fab: false,
            filters: {
                date: '',
                mollie_id: '',
                page: 0
            },
            dateModal: false
        }
    },
    created() {
        if (this.$route.params.id) this.filters.mollie_id = this.$route.params.id;
        if (this.$route.query.id) this.filters.mollie_id = this.$route.query.id;
        if (this.$route.query._id) this.filters.id = this.$route.query._id;
        
        this.getPayments();
    },
    methods: {
        async getPayments() {
            try {
                const result = await paymentApi.getPayments(this.filters);
                this.payments = await result.json();
            } catch (error) {
                console.log(error);
            }
        },
        openPaymentLink(payment) {
            window.open(payment._links.self.href, '_blank');
        },
        status(status) {
            switch (status) {
                case 'open':
                    return 'Open';
                case 'canceled':
                    return 'Geannuleerd';
                case 'expired':
                    return 'Verlopen';
                case 'failed':
                    return 'Gefaald';
                case 'paid':
                    return 'Betaald';
                default:
                    return '';
            }
        },
        iconClass(status) {
            switch (status) {
                case 'open':
                    return 'payment-icon__default';
                case 'paid':
                    return 'payment-icon__success';
                default:
                    return 'payment-icon__error';
            }
        },
        paymentIcon(status) {
            switch (status) {
                case 'open':
                    return 'mdi-help-circle';
                case 'paid':
                    return 'mdi-checkbox-marked-circle';
                default:
                    return 'mdi-close-circle-outline';
            }
        },
        viewDetails(url) {
            window.open(url, '_blank');
        },
        resetFilters() {
            this.filters = {
                date: '',
                id: '',
                page: 0
            }
        }
    },
    watch: {
        filters: {
            handler() {
                this.getPayments();
            },
            deep: true
        },
    }
}
</script>