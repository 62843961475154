const getLocation = (href) => {
    const match = href.match(/^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
    return match && {
        href: href,
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        pathname: match[5],
        search: match[6],
        hash: match[7]
    }
}

export const getHost = () => {
    const url = "" + window.location;
    const location = getLocation(url);
    if(location && location.protocol && location.hostname){
        let host = location.protocol + "//" + location.hostname;
        if (process.env.NODE_ENV === "development") {
            host = `${host}:8080`;
        }
        return host;
    }
}
