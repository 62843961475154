import * as http from './main';

const api = {
    getAvailable: () => {
        return http.get('lessons/available');
    },

    getAll: (filters) => {
        return http.get('lessons', filters);
    },

    register: (body) => {
        return http.post('lessons/register', body)
    },

    unregister: (lessonId) => {
        return http.get(`lessons/unregister`, lessonId);
    },

    getMy: () => {
        return http.get(`lessons/my`);
    },

    unregisterAdmin: (query) => {
        return http.get(`lessons/unregisteradmin`, query);
    },

    saveLesson: (lesson, notifyUsers) => {
        return http.post(`lessons`, { lesson, notifyUsers });
    },

    deleteLesson: (lessonId) => {
        return http.deleteCall(`lessons/${lessonId}`);
    },
}

export default api;