<template>
    <div class="mb-12">
        <v-img
            contain
            src="../assets/img/header-image.jpeg"
            class="mb-6"
            v-if="!auth._id"
        />
        <v-row v-if="!auth._id">
            <v-col cols="12" md="7">
                <h1>Therapy4dogs inschrijvingen</h1>
                <p class="mt-4 grey--text text--darken-2">
                    Inschrijven voor lessen, een nieuw abonnement afsluiten, inschrijven voor evenementen en meer.
                    Dat doe je hier op de inschrijvingenwebsite. Log in of registreer je hier:
                </p>
                <div class="d-flex flex-column">
                    <div>
                        <v-btn 
                            color="error"
                            @click="handleRegister"
                            >
                            Registreer je nu!
                        </v-btn>
                        <v-btn @click="$auth.loginWithRedirect()" color="primary" class="ml-2">
                            Inloggen
                        </v-btn>
                    </div>
                    <div class="mt-12">
                        <p class="grey--text text--darken-2">Je kan je ook inschrijven voor events zonder te registreren</p>
                        <v-btn color="primary" @click="$router.push('events')">
                            Inschrijven voor event
                        </v-btn>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="5">
                <v-img 
                    contain 
                    src="../assets/img/frontpage-1.jpg"
                    class="elevation-4"
                />
            </v-col>
        </v-row>
        <PersonalDashboard v-else />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import PersonalDashboard from '../components/dashboard/PersonalDashboard.vue';
import { getHost } from '@/plugins/host';

export default {
    name: 'HomeComponent',
    components: { PersonalDashboard },
    computed: {
        ...mapState({
            auth: state => state.auth
        })
    },
    methods: {
        async handleRegister() {
            this.$auth.loginWithRedirect({
                redirect_uri: `${getHost()}/registered`,
                screen_hint: 'signup',
            });
        }
    }
}
</script>