<template>
     <div class="d-flex justify-content-between">
        <v-btn text @click="$emit('close')">
            <v-icon>mdi-chevron-left</v-icon>
            Terug
        </v-btn>
        <div class="d-flex">
            <v-btn
                class="mr-2"
                icon
                text
                @click="downloadAsJson"
            >
                <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-btn
                class="mr-2"
                icon
                text
                @click="$emit('ticket-page')"
            >
                <v-icon>mdi-ticket</v-icon>
            </v-btn>
            <v-btn
                class="mr-2"
                icon
                text
                @click="$emit('generate-start-number')"
            >
                <v-icon>mdi-numeric</v-icon>
            </v-btn>
            <v-btn
                class="mr-2"
                icon
                text
                @click="$emit('show-setup')"
            >
                <v-icon>mdi-cog</v-icon>
            </v-btn>
            <v-btn
                icon
                text
                @click="$emit('add-participant')"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </div>
        <a id="downloadAnchorElem" style="display:none"></a>
    </div>
</template>

<script>
export default {
    props: {
        participants: {
            type: Array,
            required: true
        }
    },
    methods: {
        downloadAsJson() {
            var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.participants));
            var dlAnchorElem = document.getElementById('downloadAnchorElem');
            dlAnchorElem.setAttribute("href",     dataStr     );
            dlAnchorElem.setAttribute("download", "deelnemers.json");
            dlAnchorElem.click();
        }
    }
}
</script>