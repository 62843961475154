<template>
    <div class="admin-container">
        <v-row justify="center">
            <v-col sm="12" class="default-header">
                <h1>Dogsurvival beheren</h1>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm=12>
                <FiltersBase 
                    v-model="filters"
                    :filters="['date', 'future']"
                    @reset="filters = { future: true, page: 0 }"
                />
            </v-col>
        </v-row>

        <DogSurvivalList 
            :dogsurvivals="dogsurvivals"
            @refresh="getSurvivals"
            @page-changed="filters.page = $event"
            @edit="handleEditSurvival"
            @manage="handleManageSurvival"
            :filters="filters"
            v-if="!manage"
        />

        <DogSurvivalDetails 
            :selectedSurvival="selectedSurvival"
            @close="handleCloseDialog"
            @refresh="getSurvivals"
            v-else
        />

        <!-- FAB -->
        <v-speed-dial
            v-model="fab"
            direction="top"
            fab
            bottom
            right
            fixed
            open-on-hover
        >
            <template v-slot:activator>
                <v-btn v-model="fab" fab color="red darken-1" large dark>
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else>mdi-chevron-up</v-icon>
                </v-btn>
            </template>
            <v-btn @click="getSurvivals" fab color="red darken-1" small dark>
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn @click="handleEditSurvival({})" fab color="red darken-1" small dark>
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-speed-dial>
        
        <DogSurvivalForm 
            v-model="addSurvivalDialog" 
            @close="handleCloseDialog"
            :selectedSurvival="selectedSurvival"
        />
    </div>
</template>

<script>
import DogSurvivalForm from './dogsurvival/DogSurvivalForm.vue';
import DogSurvivalList from './dogsurvival/DogSurvivalList.vue';
import DogSurvivalDetails from './dogsurvival/DogSurvivalDetails.vue';
import FiltersBase from './components/FiltersBase.vue';

import dogsurvivalApi from '@/api/dogsurvival';

export default {
    components: {
        DogSurvivalList,
        DogSurvivalForm,
        DogSurvivalDetails,
        FiltersBase,
    },
    data() {
        return {
            fab: false,
            filters: {
                future: true,
                page: 0
            },
            dogsurvivals: [],
            addSurvivalDialog: false,
            selectedSurvival: {},
            manage: false,
        }
    },
    created() {
        this.getSurvivals();
    },
    methods: {
        handleManageSurvival(survival) {
            this.selectedSurvival = survival;
            this.manage = true;
        },
        handleEditSurvival(survival = {}) {
            this.selectedSurvival = survival;
            this.addSurvivalDialog = true;
        },
        handleCloseDialog() {
            this.addSurvivalDialog = false;
            this.selectedSurvival = {};
            this.manage = false;
            this.getSurvivals();
        },
        async getSurvivals() {
            try {
                const response = await dogsurvivalApi.getDogsurvivals(this.filters);
                this.dogsurvivals = await response.json();
                if (this.selectedSurvival._id) {
                    this.selectedSurvival = this.dogsurvivals.find(ds => ds._id === this.selectedSurvival._id);
                }
            } catch (error) {
                console.log(error);
            }
        }
    },
}
</script>
