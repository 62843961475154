<template>
    <v-footer
        app
        dark
        padless
        sticky
        style="z-index: 1010;"
    >
        <v-card
            flat
            tile
            class="white--text text-center"
            color="blue darken-2"
            width="100vw"
        >   
            <template v-if="$route.name === 'home' && !auth._id">
                <v-card-text v-if="$route.name === 'home' && !auth._id">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-4 white--text"
                                icon
                                v-on="on"
                                @click="bottomNavClicked('https://www.therapy4dogs.be')"
                            >
                                <v-icon size="24px">mdi-home</v-icon>
                            </v-btn>
                        </template>
                        <span>Website</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-4 white--text"
                                icon
                                v-on="on"
                                @click="bottomNavClicked('https://www.therapy4dogs.be/algemeen/contact-en-info/#page-content')"
                            >
                                <v-icon size="24px">mdi-email</v-icon>
                            </v-btn>
                        </template>
                        <span>Contact</span>
                    </v-tooltip>
                    
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-4 white--text"
                                icon
                                v-on="on"
                                @click="bottomNavClicked('https://www.facebook.com/Therapy4Dogs/')"
                            >
                                <v-icon size="24px">mdi-facebook</v-icon>
                            </v-btn>
                        </template>
                        <span>Facebook</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-4 white--text"
                                icon
                                v-on="on"
                                @click="bottomNavClicked('https://www.instagram.com/therapy4dogs/')"
                            >
                                <v-icon size="24px">mdi-instagram</v-icon>
                            </v-btn>
                        </template>
                        <span>Instagram</span>
                    </v-tooltip>
                </v-card-text>

                <v-divider></v-divider>
            </template> 

            <v-card-text class="white--text">
                &copy; {{ getCurrentYear }} - <strong>Therapy4Dogs</strong> - <privacy-policy></privacy-policy>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
import { mapState } from 'vuex';
import PrivacyPolicy from '../Utils/PrivacyPolicy.vue';

export default {
  components: { PrivacyPolicy },
    computed: {
        ...mapState({
            auth: state => state.auth
        }),
        getCurrentYear() {
            return new Date().getFullYear();
        }
    },
    methods: {
        bottomNavClicked(target) {
            window.open(target, '_blank');
        }
    },
}
</script>