<template>
    <v-dialog v-model="modal" max-width="100vw" width="800" persistent>
        <v-card>
            <v-card-title v-if="value._id">Les bewerken</v-card-title>
            <v-card-title v-else>Les toevoegen</v-card-title>
            <form @submit.prevent="handleSave">
                <v-card-text>
                    <v-row>
                        <v-col sm="12">
                            <v-text-field
                                label="Titel"
                                v-model="localValue.title"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="12" md="6">
                            <v-menu
                                ref="date"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="new Date(date).toLocaleDateString('nl-BE')"
                                        label="Datum"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        required
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date"
                                    no-title
                                    scrollable
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.date.save(date)"
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col sm="12" md="6">
                            <v-menu
                                ref="time"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="time.toString()"
                                        label="Tijd"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        required
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-model="time"
                                    format="24hr"
                                    full-width
                                    @click:minute="$refs.time.save(time)"
                                ></v-time-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="12" md="6">
                            <v-text-field
                                label="Duurtijd (in minuten)"
                                v-model="localValue.duration"
                                type="Number"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col sm="12" md="6">
                            <v-text-field
                                label="Maximum aantal deelnemers"
                                v-model="localValue.maxPeople"
                                type="Number"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Locatie"
                                v-model="localValue.location"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Plus code"
                                v-model="localValue.plusCode"
                            ></v-text-field>
                            <p class="m-0 text-caption">
                                Google Maps plus code. 
                                <a href="https://maps.google.com/pluscodes/" target="_blank">
                                    (?)
                                </a>
                                Klik 
                                <a href="#" @click.prevent="addFieldLocation">hier</a>
                                voor trainingswei
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Beschrijving (optioneel)"
                                v-model="localValue.description"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h4>Gasten toevoegen</h4>
                            <div v-for="(reg, i) in value.registrations" :key="i">
                                <v-btn
                                    icon
                                    @click="removeGuest(i)"
                                    >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                                <span>{{ reg }}</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <v-text-field
                                    label="Gast toevoegen"
                                    v-model="newGuest"
                                    @keydown.tab.prevent="addGuest"
                                ></v-text-field>
                                <v-btn
                                    icon
                                    @click="addGuest"
                                    >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-switch
                                v-model="addOne"
                                label="Nog een les toevoegen"
                            ></v-switch>
                        </v-col>
                    </v-row>
                    <v-row v-if="value._id">
                        <v-col>
                            <v-switch
                                v-model="notifyUsers"
                                label="Gebruikers verwittigen van veranderingen"
                            ></v-switch>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        type="submit"
                        >
                        Opslaan
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        text
                        @click="$emit('close-dialog')"
                        >
                        Annuleren
                    </v-btn>
                </v-card-actions>
            </form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: false
        },
        showModal: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            time: '12:00',
            date: new Date().toISOString(),
            modal: this.showModal,
            newGuest: '',
            addOne: false,
            fieldLocation: '9R5P+JP Merksplas',
            notifyUsers: false
        }
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    created() {
        if (this.value._id) this.parseDate();
        else this.createDate();
    },
    methods: {
        handleSave() {
            this.$emit('save-lesson', { addOne: this.addOne, notifyUsers: this.notifyUsers });
        },
        createDate() {
            let newDate = new Date(this.date);
            const time = this.time.split(':');
            newDate.setHours(time[0]);
            newDate.setMinutes(time[1]);
            this.localValue.date = newDate.toISOString();
        },
        parseDate() {
            const date = new Date(this.value.date);
            this.date = date.toISOString();
            this.time = `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
        },
        reset() {
            this.time = '12:00';
            this.date = new Date().toISOString();
            this.notifyUsers = false;
        },
        addGuest() {
            const newGuest = {
                ...this.value,
                registrations: [...this.value.registrations, this.newGuest]
            }
            this.$emit('input', newGuest);
            this.newGuest = '';
        },
        removeGuest(i) {
            const newRegs = [ ...this.value.registrations ]
            newRegs.splice(i, 1);
            const newGuest = {
                ...this.value,
                registrations: [ ...newRegs ]
            }
            this.$emit('input', newGuest);
        },
        addFieldLocation() {
            this.localValue.plusCode = this.fieldLocation;
            this.localValue.location = 'Trainingsweide Merksplas';
            this.$forceUpdate();
        }
    },
    watch: {
        showModal: {
            handler(val) {
                if (!val) this.reset();
                this.modal = val;
            }
        },
        time: {
            handler() {
                this.createDate();
            }
        },
        date: {
            handler() {
                this.createDate();
            }
        },
        value: {
            handler(newVal, oldVal) {
                if (newVal._id === oldVal._id) return;
                if (newVal._id == null && !newVal.name) this.reset();
                else this.parseDate();
            }
        }
    },
}
</script>