<template>
    <div>
        <v-row justify="center">
            <v-col>
                <v-simple-table>
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Factuurnummer</th>
                            <th class="text-left">Klant</th>
                            <th class="text-left">Datum</th>
                            <th class="text-left">Einde betalingstermijn</th>
                            <th class="text-left">Betaald?</th>
                            <th class="text-left">Verzonden?</th>
                            <th class="text-left">Bedrag</th>
                            <th class="text-left">Bedrijf</th>
                            <th class="text-left">Acties</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="invoice in invoices" :key="invoice._id" :class="{'red lighten-4': invoice.credited}">
                            <td>{{ invoice.invoiceNumber }}</td>
                            <td v-if="invoice.client">
                                {{ invoice.client.name }}<br>
                                {{ invoice.client.street }}<br>
                                {{ invoice.client.address}}<br>
                                {{ invoice.client.vat }}

                            </td>
                            <td>{{ new Date(invoice.createdAt).toLocaleDateString('nl-BE') }}</td>
                            <td :style="getDateStyle(invoice.due)">
                                {{ new Date(invoice.due).toLocaleDateString('nl-BE') }}
                            </td>
                            <td>
                                <v-btn icon @click="togglePaid(invoice)">
                                    <v-icon v-if="invoice.paid" color="green">mdi-check</v-icon>
                                    <v-icon v-else color="red">mdi-close</v-icon>
                                </v-btn>
                            </td>
                            <td>
                                <v-btn icon @click="toggleSent(invoice)">
                                    <v-icon v-if="invoice.sent" color="green">mdi-check</v-icon>
                                    <v-icon v-else color="red">mdi-close</v-icon>
                                </v-btn>
                            </td>
                            <td>
                                <v-btn text dense @click="showInvoiceLines(invoice)">
                                    €{{ calculateAmount(invoice) }}
                                </v-btn>
                            </td>
                            <td>
                                {{ getCompanyValue(invoice)  }}
                            </td>
                            <td>
                                <v-btn icon @click="copyInvoice(invoice)">
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                                <v-btn icon @click="editInvoice(invoice)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn icon @click="createPrintable(invoice)">
                                    <v-icon>mdi-printer</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>

        <!-- FAB -->
        <v-speed-dial
            v-model="fab"
            direction="top"
            fab
            bottom
            right
            fixed
            open-on-hover
        >
            <template v-slot:activator>
                <v-btn v-model="fab" fab color="red darken-1" large dark>
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else>mdi-chevron-up</v-icon>
                </v-btn>
            </template>
            <v-btn @click="getInvoices" fab color="red darken-1" small dark>
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn @click="createInvoice" fab color="red darken-1" small dark>
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-speed-dial>

        <!-- CREATE DIALOG -->
        <v-dialog
            v-model="createInvoiceDialog"
            max-width="90vw"
            scrollable
            >   
            <create-invoice
                :selectedInvoice="selectedInvoice"
                @save="handleSave"
                @close="handleClose"
            ></create-invoice>
        </v-dialog>

        <!-- DETAIL DIALOG -->
        <v-dialog v-model="invoiceLines">
            <v-card>
                <v-card-title class="headline">Factuurlijnen</v-card-title>
                <v-card-text>
                    <v-simple-table v-if="selectedInvoice">
                        <thead>
                            <tr>
                                <th class="text-left">Beschrijving</th>
                                <th class="text-left">Hoeveelheid</th>
                                <th class="text-left">Prijs per stuk</th>
                                <th class="text-left">BTW</th>
                                <th class="text-left">Prijs incl</th>
                                <th class="text-left">Nota</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(invoiceLine, i) in selectedInvoice.lines" :key="i">
                                <td>{{ invoiceLine.description }}</td>
                                <td>{{ invoiceLine.amount }}</td>
                                <td>€{{ invoiceLine.pricePer }}</td>
                                <td>{{ invoiceLine.tax }}%</td>
                                <td>€{{ +invoiceLine.priceIn }}</td>
                                <td>{{ invoiceLine.comment }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="invoiceLines = false">Sluiten</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Printable --> 
        <v-dialog v-model="showPrintable">
            <print-invoice 
                v-if="showPrintable"
                :invoice="selectedInvoice" 
                @close="showPrintable = false"
            ></print-invoice>
        </v-dialog>
    </div>
</template>

<script>
import createInvoice from './CreateInvoice';
import PrintInvoice from './PrintInvoice';

import invoiceApi from '@/api/invoices';

export default {
    components: {
        createInvoice,
        PrintInvoice
    },
    props: {
        filters: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            fab: false,
            invoices: [],
            createInvoiceDialog: false,
            selectedInvoice: null,
            invoiceLines: false,
            showPrintable: false,
        }
    },
    mounted() {
        this.getInvoices();
    },
    methods: {
        async getInvoices() {
            try {
                const result = await invoiceApi.getInvoices(this.filters);
                this.invoices = await result.json();
            } catch (error) {
                console.log(error);
            }
        },
        createInvoice() {
            this.selectedInvoice = null;
            this.createInvoiceDialog = true;
        },
        editInvoice(invoice) {
            this.selectedInvoice = invoice;
            this.createInvoiceDialog = true;
        },
        handleSave(invoice) {
            if (invoice._id) this.updateInvoice(invoice);
            else this.saveInvoice(invoice);
        },
        async saveInvoice(invoice) {
            try {
                await invoiceApi.saveInvoice(invoice);
            } catch (error) {
                console.log(error);
            } finally {
                this.handleClose();
                this.getInvoices();
            }
        },
        async updateInvoice(invoice) {
            try {
                await invoiceApi.updateInvoice(invoice);
            } catch (error) {
                console.log(error);
            } finally {
                this.handleClose();
                this.getInvoices();
            }
        },
        calculateAmount(invoice) {
            let amount = 0;

            invoice.lines.forEach(element => {
                amount += (element.priceIn * element.amount);
            });

            return amount.toFixed(2);
        },
        confirmDelete(invoice) {
            this.selectedInvoice = invoice;
            this.confirmDeleteDialog = true;
        },
        togglePaid(invoice) {
            this.selectedInvoice = invoice;
            this.selectedInvoice.paid = !this.selectedInvoice.paid;
            this.updateInvoice(invoice);
        },
        toggleSent(invoice) {
            this.selectedInvoice = invoice;
            this.selectedInvoice.sent = !this.selectedInvoice.sent;
            this.updateInvoice(invoice);
        },
        showInvoiceLines(invoice) {
            this.selectedInvoice = invoice;
            this.invoiceLines = true;
        },
        getDateStyle(date) {
            if (new Date(date).getTime() < new Date().getTime()) {
                return 'font-weight: 800; color: red;';
            }

            return '';
        },
        getCompanyValue(invoice) {
            switch (invoice.company) {
                case 'therapy4dogs': return 'T4D';
                case 'svbits': return 'SvBits';
            }
        },
        createPrintable(invoice) {
            this.selectedInvoice = invoice;
            this.showPrintable = true;
        },
        copyInvoice(invoice) {
            this.selectedInvoice = {
                client: invoice.client,
                comment: invoice.comment,
                company: invoice.company,
                lines: invoice.lines,
                paid: false,
                invoiceNumber: ''
            };

            this.createInvoiceDialog = true;
        },
        handleClose() {
            this.selectedInvoice = null;
            this.createInvoiceDialog = false;
        }
    },
    watch: {
        filters: {
            handler() {
                this.getInvoices();
            },
            deep: true
        }
    }
}
</script>
