<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-list>
                    <v-list-item two-line v-for="ds in dogsurvivals" :key="ds._id">
                        <v-list-item-avatar>
                            <v-btn icon @click="activateDogsurvival(ds)">
                                <v-icon color="grey lighten-1" v-if="!ds.active">mdi-dog</v-icon>
                                <v-icon color="green lighten-1" v-else>mdi-check</v-icon>
                            </v-btn>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>
                                {{ ds.title }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ $formatDate(ds.date) }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        
                        <v-list-item-action>
                            <div class="d-flex">
                                <v-btn icon class="mr-6" @click="$emit('edit', ds)">
                                    <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn icon @click="$emit('manage', ds)">
                                    <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                                </v-btn>
                            </div>
                            
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <CustomPagination
                    :page="filters.page"
                    @page-changed="$emit('page-changed', $event)"
                    :currentItems="dogsurvivals.length"
                ></CustomPagination>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import CustomPagination from '@/components/Generic/CustomPagination';

import dogsurvivalApi from '@/api/dogsurvival';

export default {
    components: {
        CustomPagination
    },
    props: {
        dogsurvivals: {
            type: Array,
            required: false,
            default: () => []
        },
        filters: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    methods: {
        async activateDogsurvival(ds) {
            try {
                await dogsurvivalApi.activateDogsurvival(ds._id, !ds.active);
                this.$emit('refresh');
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>
