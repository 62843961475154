<template>
    <v-app>
        <!-- LOADER -->
        <T4DLoader v-if="!showApp" />

        <!-- HEADER -->
        <header-component />

        

        <!-- ROUTER -->
        <v-main>
            <div class="main-container">
                <NavBarComponent />
                <v-container fluid>
                    <transition name="slide-fade" mode="out-in">
                        <router-view class="router"></router-view>
                    </transition>
                </v-container>
            </div>
        </v-main>

        <!-- FOOTER -->
        <footer-component />
        
        <!-- CUSTOM IE CHECK -->
        <ie-check></ie-check>

        <!-- COOKIE NOTICE -->
        <cookie-notice></cookie-notice>
    </v-app>
</template>

<script>
import IeCheck from './components/Utils/IECheck.vue';
import HeaderComponent from './components/Layout/HeaderComponent.vue';
import FooterComponent from './components/Layout/FooterComponent.vue';
import NavBarComponent from './components/Layout/NavBarComponent.vue';
import T4DLoader from './components/Generic/T4DLoader.vue';
import CookieNotice from './components/Utils/CookieNotice.vue';

import userApi from '@/api/users';

import * as types from '@/store/types';

export default {
    name: 'App',
    components: {
    IeCheck,
    HeaderComponent,
    FooterComponent,
    T4DLoader,
    CookieNotice,
    NavBarComponent
},
    data() {
        return {
            showApp: false
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.$auth.loading) return;
            
            try {
                await this.loadTokenIntoStore();
                await this.getUser();  
            } catch (error) {
                console.log(error);
            }
                   
            this.setShowApp();
        },
        async loadTokenIntoStore() {
            const authToken = await this.$auth.getTokenSilently();
            localStorage.setItem("auth_token", authToken);
        },
        async getUser() {
            if (!this.$auth.isAuthenticated) return;
            
            try {
                const response = await userApi.getUser();
                const user = await response.json();
                this.$store.dispatch(types.SET_AUTH, user);
            } catch (error) {
                console.log(error);
            }
        },
        setShowApp() {
            setTimeout(() => {
                this.showApp = true;
            }, 400);
        }
    },
    watch: {
        '$auth.loading': {
            handler(val) {
                if (!val) this.init();
            }
        }
        
    }
};
</script>
