import { getInstance } from '../plugins/auth';

const baseUrl = process.env.VUE_APP_API || '/api/';
let headers = {};

const createHeaders = () => {
    headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
        'user': JSON.stringify(getInstance().user)
    }
}

const createUrl = (url, query = {}) => {
    createHeaders();

    if (Object.keys(query).length === 0) return `${baseUrl}${url}`;
    
    let queryString = '';
    for (const key in query) {
        queryString += `${key}=${query[key]}&`;
    }

    return `${baseUrl}${url}?${queryString}`;
}

export const get = (url, query = {}) => {
    return fetch(createUrl(url, query), {
        method: 'GET',
        headers
    });
}

export const post = (url, body) => {
    return fetch(createUrl(url), {
        method: 'POST',
        body: JSON.stringify(body),
        headers
    });
}

export const put = (url, body) => {
    return fetch(createUrl(url), {
        method: 'PUT',
        body: JSON.stringify(body),
        headers
    });
}

export const deleteCall = (url, id) => {
    return fetch(createUrl(url, id ? { id } : {}), {
        method: 'DELETE',
        headers
    });
}