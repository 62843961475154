export const formatDate = (date, format = { year: 'numeric', month: '2-digit',  day: '2-digit' }) => {
    return new Date(date).toLocaleDateString('nl-BE', format);
}

export const formatTime = (date) => {
    let time = new Date(date).toLocaleTimeString('nl-BE', {
        timeStyle: 'short'
    });

    if (time === 'Invalid Date') time = "";
    return time;
}

const dateTimeFormatter = {
    install: (Vue) => {
        Vue.prototype.$formatDate = (date, format) => formatDate(date, format);
        Vue.prototype.$formatTime = (date) => formatTime(date)
    }
}

export default dateTimeFormatter;