<template>
    <div>
        <v-dialog
            v-model="showDialog"
            width="500"
            max-width="500"
            >
            <v-card>
                <v-card-title>Kies starttijd en interval</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="localStartTime"
                                    label="Starttijd"
                                    type="time"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="localInterval"
                                    label="Interval"
                                    hint="Interval in minuten"
                                    type="number"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="$emit('close')"
                    >
                        Opslaan
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        showSetup: {
            type: Boolean,
            required: false,
            default: false
        },
        startTime: {
            type: String,
            required: false,
            default: '',
        },
        interval: {
            type: Number,
            required: false,
            default: 2,
        }
    },
    computed: {
        showDialog: {
            get() {
                return this.showSetup;
            },
            set(value) {
                this.$emit('close', value);
            }
        },
        localStartTime: {
            get() {
                return this.startTime;
            },
            set(value) {
                this.$emit('update:start-time', value);
            }
        },
        localInterval: {
            get() {
                return this.interval;
            },
            set(value) {
                this.$emit('update:interval', value);
            }
        }
    },
    methods: {
        handleSubmit() {

        }
    }
}
</script>
