<template>
    <div class="admin-container">
        <v-row justify="center">
            <v-col sm="12" class="default-header">
                <h1>Facturen beheren</h1>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm="12">
                <FiltersBase 
                    v-model="filters"
                    :filters="['date', 'unpaid', 'invoiceNumber']"
                    @reset="filters = { future: true, page: 0 }"
                />
            </v-col>
        </v-row>

        <template>
            <invoice-overview :filters="filters"></invoice-overview>
        </template>
    </div>
</template>

<script>
import InvoiceOverview from './invoices/InvoiceOverview';
import FiltersBase from './components/FiltersBase.vue';

export default {
    components: {
        InvoiceOverview,
        FiltersBase,
    },
    data() {
        return {
            filters: {
                page: 0,
                unpaid: false
            }
        }
    }
}
</script>