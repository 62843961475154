<template>
    <div class="admin-container">
        <v-row>
            <v-col sm=9 class="default-header">
                <h1>Logs</h1>
            </v-col>
            <v-col sm=3 class="d-flex justify-content-end align-items-center">
                <span v-if="lastUpdate" class="mr-4 text-caption">
                    Laatste update:
                    {{ $formatDate(lastUpdate) }} - {{ new Date(lastUpdate).toLocaleTimeString() }}
                </span>
                <v-progress-circular
                    v-if="logInterval"
                    indeterminate
                    size="20"
                    class="mr-2"
                ></v-progress-circular>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm=12>
                <FiltersBase 
                    v-model="filters"
                    :filters="['logType', 'date']"
                    @reset="resetFilters"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col sm=12>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">Datum en tijd</th>
                                <th class="text-left">Type</th>
                                <th class="text-left">Gebruiker</th>
                                <th class="text-left">Les</th>
                                <th class="text-left">Betaling</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="log in logs" :key="log._id">
                                <td>{{ $formatDate(log.createdAt) }} - {{ $formatTime(log.createdAt) }}</td>
                                <td>{{ getType(log.type) }}</td>
                                <td>
                                    <!-- Wrapping this in a temp v-if until we've got the backend stuff worked out. -->
                                    <span v-if="log.user">{{ `${log.user.firstName} ${log.user.lastName} - ${log.user.email}` }}</span>
                                </td>
                                <td>
                                    <v-btn icon v-if="log.lesson" @click="$router.push({ path: 'dogschooladmin', query: { id: log.lesson }})">
                                        <v-icon>mdi-dog</v-icon>
                                    </v-btn>
                                </td>
                                <td>
                                    <v-btn icon v-if="log.payment" @click="$router.push({ path: 'paymentadmin', query: { _id: log.payment }})">
                                        <v-icon>mdi-circle-multiple-outline</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <CustomPagination
                    :page="filters.page"
                    @page-changed="filters.page = $event"
                    :currentItems="logs.length"
                ></CustomPagination>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import logApi from '@/api/logs';
import CustomPagination from '@/components/Generic/CustomPagination.vue';
import FiltersBase from './components/FiltersBase.vue';

export default {
    components: {
        CustomPagination,
        FiltersBase,
    },
    data() {
        return {
            logs: [],
            logTypes: [
                { value: 'register-event', text: 'Geregistreerd voor event' }, 
                { value: 'register-lesson', text: 'Ingeschreven voor les' },
                { value: 'unregister-lesson', text: 'Uitgeschreven voor les' },
                { value: 'payment-received', text: 'Betaling ontvangen' },
                { value: 'user-created', text: 'Nieuwe gebruiker' },
                { value: 'presence-added', text: 'Beurtenkaart geknipt' },
                { value: 'reminder-mail-sent', text: 'Herinneringsmail abonnement verstuurd' },
            ],
            logInterval: null,
            filters: {
                page: 0,
                logType: '',
                date: '',
            },
            dateModal: false,
            lastUpdate: null
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.getLogs();
            this.logInterval = setInterval(() => {
                this.getLogs();
            }, 10000);
        },
        async getLogs() {
            try {
                const response = await logApi.getLogs(this.filters);
                this.logs = await response.json();
                this.lastUpdate = new Date();
            } catch (error) {
                console.log(error);
                this.$auth.loginWithRedirect();
            }
        },
        getType(type) {
            const typeTranslations = {
                'register-event': 'Geregistreerd voor event',
                'register-lesson': 'Ingeschreven voor les',
                'unregister-lesson': 'Uitgeschreven voor les',
                'payment-received': 'Betaling ontvangen',
                'user-created': 'Nieuwe gebruiker',
                'presence-added': 'Beurtenkaart geknipt',
                'reminder-mail-sent': 'Herinneringsmail abonnement verstuurd',
            }
            return typeTranslations[type];
        },
        resetFilters() {
            this.filters = {
                page: 0,
                logType: '',
            }
        }
    },
    watch: {
        filters: {
            handler() {
                this.getLogs();
            },
            deep: true
        }
    },
    destroyed() {
        clearInterval(this.logInterval);
    }
}
</script>
