<template>
    <div>
        <transition name="slide-fade" mode="out-in">
            <router-view name="admin" />
        </transition>
    </div>
</template>

<script>
export default {
    name: 'AdminWrapper'
}
</script>