<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            Abonnementen
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Titel
                            </th>
                            <th class="text-left">
                                Start
                            </th>
                            <th class="text-left">
                                Einde
                            </th>
                            <th class="text-left">
                                Beurten
                            </th>
                            <th class="text-left">
                                Acties
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="sub in validSubscriptions"
                            :key="sub._id"
                            >
                            <td>{{ sub.title }}</td>
                            <td>{{ $formatDate(sub.start) }}</td>
                            <td>{{ displayEndDate(sub) }}</td>
                            <td>{{ sub.turns }}</td>
                            <td class="text-nowrap">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn 
                                            icon 
                                            @click="$emit('add-present', sub)" 
                                            v-if="+sub.turns > 0"
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            <v-icon>mdi-marker-check</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Aanwezigheid toevoegen</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn 
                                            icon 
                                            @click="$emit('open-subscription-dialog', sub)" 
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Bewerken</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn 
                                            icon 
                                            @click="$emit('delete-subscription', sub._id)" 
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Verwijderen</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-switch
                v-model="oldSubs"
                label="Toon oude abonnementen"
            ></v-switch>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
    props: {
        detailUser: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            oldSubs: false,
        }
    },
    computed: {
        validSubscriptions() {
            if (this.oldSubs) return this.detailUser.subscriptions.slice().reverse();
            
            const validSubs = this.detailUser.subscriptions.filter(sub => {
                const end = new Date(sub.start);
                end.setMonth(end.getMonth() + sub.duration);
                end.setDate(end.getDate() + 1);
                return end >= new Date();
            });
            return validSubs.slice().reverse();
        }
    },
    methods: {
        createEndDate(sub) {
            const start = new Date(sub.start);
            const duration = new Date(start.setMonth(start.getMonth() + sub.duration));
            return duration;
        },
        displayEndDate(sub) {
            const date = this.createEndDate(sub);
            return this.$formatDate(date);
        },
    }
}
</script>
