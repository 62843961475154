import { getInstance } from '../../plugins/auth';
import userApi from '@/api/users';

import * as types from '@/store/types';
import store from '@/store'

const loadTokenIntoStore = async () => {
    try {
        const instance = getInstance();
        const authToken = await instance.getTokenSilently();
        localStorage.setItem("auth_token", authToken);
    } catch (error) {
        console.log(error);
    }
}

const getUser = async () => {
    try {
        const response = await userApi.getUser();
        const user = await response.json();
        store.dispatch(types.SET_AUTH, user);
        return 0;
    } catch (error) {
        console.log(error);
        return 1;
    }
}

export const authGuard = async (to, from, next) => {
    const authService = getInstance();

    const fn = async () => {
        if (!authService.isAuthenticated) return next('/');  

        await loadTokenIntoStore();
        await getUser();
        // If the user is authenticated, continue with the route
        if (authService.isAuthenticated) return next();

        // Otherwise, log in
        authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    };

    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) return fn();

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch("loading", loading => {
        if (loading === false) return fn();
    });
};