import * as http from './main';

const api = {
    getDogsurvivals: () => {
        return http.get('dogsurvivaladmin');
    },

    createDogsurvival: (dogsurvival) => {
        return http.post('dogsurvivaladmin', dogsurvival);
    },

    activateDogsurvival: (id, active) => {
        return http.get(`dogsurvivaladmin/activate`, { id, active });
    },

    updateParticipant: (dogSurvivalId, participantId, update) => {
        return http.post(`dogsurvivaladmin/update-participant`, { dogSurvivalId, participantId, update });
    },

    addParticipant: (dogSurvivalId, participant) => {
        return http.post(`dogsurvivaladmin/add-participant`, { dogSurvivalId, participant });   
    },

    deleteParticipant: (dogSurvivalId, participantId) => {
        return http.post(`dogsurvivaladmin/delete-participant`, { dogSurvivalId, participantId });
    },

    getCurrentDogSurvival: () => {
        return http.get('dogsurvival');
    },

    generatePayment: (participants, dogsurvivalId) => {
        return http.post('dogsurvival', { participants, dogsurvivalId });
    },
};

export default api;