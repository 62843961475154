import * as http from './main';

const api = {
    getInvoices: (query) => {
        return http.get('invoices', query);
    },

    saveInvoice: (invoice) => {
        return http.post('invoices', invoice);
    },

    updateInvoice: (invoice) => {
        return http.put('invoices', invoice);
    }
}

export default api;