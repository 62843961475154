import * as http from './main';

const api = {
    getSubscriptions: () => {
        return http.get('subscriptions');
    },

    buySubscription: (subId) => {
        return http.post(`subscriptions/buy-subscription/${subId}`)
    },

    createSubscription: (newSub) => {
        return http.post('subscriptions', newSub);
    },

    updateSubscription: (subId, update) => {
        return http.put(`subscriptions/${subId}`, update);
    },

    deleteSubscription: (subId) => {
        return http.deleteCall(`subscriptions/${subId}`);
    },

    deleteUserSubscription: (userId, regId) => {
        return http.deleteCall(`subscriptions/usersubscription/${userId}/${regId}`);
    }
}

export default api;