<template>
    <v-card>
        <v-card-title class="headline">{{ edit ? 'Abonnement aanpassen' : 'Abonnement aanmaken' }}</v-card-title>
        <v-card-text>
            <v-row>
                <v-col sm=12>
                    <v-text-field
                        label="Titel"
                        v-model="subscription.title"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm=12>
                    <v-text-field
                        label="Prijs"
                        type=Number
                        min="0"
                        step="0.01"
                        v-model="subscription.price"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm=12>
                    <v-text-field
                        label="Duurtijd in maanden"
                        type=Number
                        min="1"
                        step="1"
                        v-model="subscription.duration"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm=12>
                    <v-text-field
                        label="Aantal beurten"
                        type=Number
                        min="-1"
                        step="1"
                        hint="Vul het aantal beurten in. -1 betekent een onbeperkt abonnement."
                        v-model="subscription.turns"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="edit">
                <v-col sm=12>
                    <v-menu
                        ref="menu"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="new Date(subscription.start).toLocaleDateString('nl-BE')"
                                @input="subscription.start = $event"
                                prepend-icon="mdi-calendar"
                                label="Startdatum"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="subscription.start"
                            no-title
                            scrollable
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm=12>
                    <v-textarea
                        label="Beschrijving"
                        v-model="subscription.description"
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row v-if="!edit">
                <v-col sm=12>
                        <v-switch
                            v-model="subscription.active"
                            :label="`Abonnement actief`"
                        ></v-switch>
                </v-col>
            </v-row>
            <v-row v-if="edit && subscription.createdAt && subscription.updatedAt">
                <v-col sm=12>
                    <p>Aangemaakt: {{ new Date(subscription.createdAt).toLocaleString('nl-BE') }}</p>
                    <p>Laatste update: {{ new Date(subscription.updatedAt).toLocaleString('nl-BE') }}</p>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('close-dialog')">Annuleren</v-btn>
            <v-btn color="primary" @click="$emit('create-subscription')">Bevestigen</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: ['subscription', 'edit', 'uid'],
}
</script>