<template>
    <v-dialog :value="showRegistrations" @click:outside="$emit('close')">
        <v-card v-if="editEvent">
            <v-card-title class="headline">Inschrijvingen</v-card-title>
            <v-card-text>
                <h5>Manuele inschrijvingen</h5>
                <ul>
                    <li v-for="(registration, i) in editEvent.manualRegistrations" :key="`${i}-${registration}`">
                        {{ registration }}
                    </li>
                </ul>
                <h5>Betaalde inschrijvingen</h5>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-left">Naam</th>
                            <th class="text-left">Soort inschrijving</th>
                            <th class="text-left">Hond</th>
                            <th class="text-left">E-mail</th>
                            <th class="text-left">Acties</th>
                        </tr>
                    </thead>
                    <tbody v-for="registration in editEvent.registrations" :key="registration.id">
                        <tr>
                            <!-- Name -->
                            <td v-if="registration.user && registration.user._id">{{ `${registration.user.firstName} ${registration.user.lastName}` }}</td> 
                            <td v-else-if="registration.unregisteredUser !== null && registration.unregisteredUser.email">{{ `${registration.unregisteredUser.firstName} ${registration.unregisteredUser.lastName}` }}</td>
                            <td v-else>{{ `${registration.firstName} ${registration.lastName}` }} </td>
                           
                            <!-- Reg type -->
                            <td>{{ registration.type }}</td>

                            <!-- Dog -->
                            <td>
                                <span v-if="registration.dog && registration.dog.name !== ''">
                                    {{ registration.dog.name }} ({{ registration.dog.breed }} - {{ registration.dog.age }} jaar)
                                </span>
                            </td>

                            <!-- Email -->
                            <td v-if="!registration.user">{{ registration.email || registration.unregisteredUser.email }}</td>
                            <td v-else>{{ registration.user.email }}</td>

                            <!-- Actions --> 
                            <td>
                                <v-icon @click="searchPayment(registration.paymentRef)">
                                    mdi-cash
                                </v-icon>
                                <v-icon @click="deleteRegistration(registration)">
                                    mdi-delete
                                </v-icon>
                            </td>
                        </tr>

                        <!-- Guests -->
                        <tr v-for="guest in registration.guests"  :key="guest.id">
                            <td>{{ `${guest.firstName} ${guest.lastName}` }}</td>
                            <td>{{ guest.type }}</td>
                            <td>
                                <span v-if="guest.dog">
                                    {{ guest.dog.name }} ({{ guest.dog.breed }} - {{ guest.dog.age }} jaar)
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>

                        <!-- Remarks -->
                        <tr style="background-color: #ededed;">
                            <td colspan="7">
                                Opmerkingen: {{ registration.remarks }}
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="error" @click="$emit('close')">Sluiten</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import paymentsApi from '@/api/payments';

export default {
    props: {
        showRegistrations: {
            type: Boolean,
            required: true,
        },
        editEvent: {
            type: Object,
            required: false,
            default: () => {}
        },
    },
    methods: {
        searchPayment(id) {
            this.$router.push({ name: 'paymentadmin', params: { id } })
        },
        async deleteRegistration(registration) {
            const result = await this.$swal.fire({
                title: 'Verwijderen?',
                text: `Dit kan niet ongedaan gemaakt worden.`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Annuleren'
            });

            if (result.isConfirmed) {
                try {
                    await paymentsApi.deleteRegistration(this.editEvent._id, registration._id);
                    this.$emit('refresh');
                } catch (error) {
                    console.log(error);
                }
            }
        },
        toggleDetailsDialog(event = {}) {
            this.details = event;
            this.detailsDialog = !this.detailsDialog;
        },
    }
}
</script>
