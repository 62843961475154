<template>
    <div class="admin-container">
        <v-row justify="center">
            <v-col sm="12" class="default-header">
                <h1>Abonnementen beheren</h1>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm="12">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Sorteren
                                </th>
                                <th class="text-left">
                                    Titel
                                </th>
                                <th class="text-left">
                                    Duurtijd
                                </th>
                                <th class="text-left">
                                    Beschrijving
                                </th>
                                <th class="text-left">
                                    Prijs
                                </th>
                                <th class="text-left">
                                    Beurten
                                </th>
                                <th class="text-left">
                                    Actief
                                </th>
                                <th class="text-left">
                                    Acties
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(sub, i) in subscriptions"
                                :key="sub._id"
                            >
                                <td class="text-center">
                                    <div class="d-flex flex-column text-center justify-center align-center">
                                        <v-btn icon :disabled="i === 0" @click="sort(sub._id, -1)">
                                            <v-icon>mdi-chevron-up</v-icon>
                                        </v-btn>
                                        {{ sub.order }}
                                        <v-btn icon :disabled="i === subscriptions.length -1" @click="sort(sub._id, 1)">
                                            <v-icon>mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </div>
                                    
                                </td>
                                <td>{{ sub.title }}</td>
                                <td class="text-nowrap">{{ durationText(sub.duration) }}</td>
                                <td>{{ sub.description }}</td>
                                <td>€{{ sub.price }}</td>
                                <td>{{ sub.turns }}</td>
                                <td>
                                    <v-btn icon @click="toggleActive(sub._id)">
                                        <v-icon v-if="sub.active" color="green">mdi-check</v-icon>
                                        <v-icon v-else color="red">mdi-close</v-icon>
                                    </v-btn>
                                    
                                </td>
                                <td>
                                    <div class="d-flex">
                                        <v-btn icon @click="editSub(sub)">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn icon @click="deleteSubscription(sub)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </div>
                                    
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>

        <!-- FAB -->
        <v-speed-dial
            v-model="fab"
            direction="top"
            fab
            bottom
            right
            fixed
            open-on-hover
        >
            <template v-slot:activator>
                <v-btn v-model="fab" fab color="red darken-1" large dark>
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else>mdi-chevron-up</v-icon>
                </v-btn>
            </template>
            <v-btn @click="getSubscriptions()" fab color="red darken-1" small dark>
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn @click="subscriptionDialog = true" fab color="red darken-1" small dark>
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-speed-dial>

        <!-- ADD SUBSCRIPTION DIALOG -->
        <v-dialog v-model="subscriptionDialog" max-width="100vw" width="800">
            <create-subscription 
                :subscription="newSubscription"
                @close-dialog="subscriptionDialog = false"
                @create-subscription="saveSubscription"
            ></create-subscription>
        </v-dialog>

        <!-- UPDATE SUBSCRIPTION DIALOG -->
        <v-dialog v-model="editDialog" max-width="100vw" width="800">
            <create-subscription 
                :subscription="editSubscription"
                @close-dialog="editDialog = false"
                @create-subscription="closeEditSub"
            ></create-subscription>
        </v-dialog>
    </div>
</template>

<script>
import CreateSubscription from './components/CreateSubscription';
import subApi from '@/api/subscriptions';

const initState = {
    title: '',
    duration: 0,
    description: '',
    price: 0,
    active: false,
    turns: -1,
    start: new Date().toLocaleDateString('nl-BE')
}

export default {
    components: {
        CreateSubscription
    },
    data() {
        return {
            fab: false,
            subscriptions: null,
            newSubscription: { ...initState },
            subscriptionDialog: false,
            editDialog: false,
            editSubscription: {},
        }
    },
    mounted() {
        this.getSubscriptions();
    },
    methods: {
        async getSubscriptions() {
            try {
                const response = await subApi.getSubscriptions();
                this.subscriptions = await response.json();
                this.sortArray();
            } catch (error) {
                console.log(error);
            }
        },
        async saveSubscription() {
            const body = { ...this.newSubscription, start: null };

            try {
                await subApi.createSubscription(body);
            } catch (error) {
                console.log(error);
            } finally {
                this.subscriptionDialog = false;
                this.newSubscription = { ...initState };
                this.getSubscriptions();
            }
        },
        async toggleActive(id) {
            const subscription = this.subscriptions.find(x => x._id === id);
            this.updateSubscription(id, { active: !subscription.active });
        },
        async updateSubscription(id, update) {
            try {
                await subApi.updateSubscription(id, update);
            } catch (error) {
                console.log(error);
            } finally {
                this.getSubscriptions();
            }
        },
        async deleteSubscription(sub) {
            const result = await this.$swal.fire({
                title: 'Verwijderen?',
                text: `Dit kan niet ongedaan gemaakt worden.`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Annuleren'
            });

            if (result.isConfirmed) {
                try {
                    await subApi.deleteSubscription(sub._id);
                } catch (error) {
                    console.log(error);
                } finally {
                    this.getSubscriptions();
                }
            }            
        },
        editSub(sub) {
            this.editSubscription = { ...sub };
            this.editDialog = true;
        },
        async closeEditSub() {
            await this.updateSubscription(this.editSubscription._id, this.editSubscription);
            this.editDialog = false;
            this.editSubscription = {};
        },
        sort(id, dir) {
            const sub = this.subscriptions.find(x => x._id === id);
            sub.order += dir;
            this.sortArray();
            this.updateSubscription(id, sub);
        },
        sortArray() {
            this.subscriptions.sort((a, b) => {
                if (a.order < b.order) return -1;
                if (a.order > b.order) return 1;
                return 0;
            });
        },
        durationText(duration) {
            if (duration == 1) return `1 maand`;
            return `${duration} maanden`;
        }
    },
}
</script>
