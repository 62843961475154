<template>
    <div class="admin-container">
        <v-row justify="center">
            <v-col sm="12" class="default-header">
                <h1>Berichten beheren</h1>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm=12 md="4">
                <v-select
                    :items="locations"
                    v-model="selectedType"
                    label="Locatie"
                    disabled
                ></v-select>
            </v-col>
            <v-col sm="12" md="4">
                <v-select
                    :items="priority"
                    v-model="message.priority"
                    label="Prioriteit"
                ></v-select>
            </v-col>
            <v-col sm="12" md="4">
                <v-switch
                    v-model="message.published"
                    label="Gepubliceerd"
                ></v-switch>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <vue-editor 
                    v-model="message.message"
                    :editorToolbar="editorToolbar"
                ></vue-editor>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <div v-html="message.message"></div>
            </v-col>
        </v-row>

        <!-- FAB -->
        <v-fab-transition>
            <v-btn
                fab
                large
                bottom
                right
                fixed
                dark
                color="red darken-1"
                class="mb-12"
                @click="handleSave"
            >
                <v-icon>mdi-content-save</v-icon>
            </v-btn>
        </v-fab-transition>

        <!-- SNACKBAR -->
        <v-snackbar v-model="snackbar">
            {{ snackbarMessage }}
        </v-snackbar>
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import messagesApi from '@/api/messages';

export default {
    components: {
        VueEditor
    },
    data() {
        return {
            fab: false,
            editorToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["code-block"], ["link"]
            ],
            locations: [
                { text: 'Hondenschool', value: 'dogschool' }
            ],
            priority: [
                { text: 'Laag', value: 'info' },
                { text: 'Normaal', value: 'warning' },
                { text: 'Hoog', value: 'error' }
            ],
            selectedType: 'dogschool',
            message: {
                type: 'dogschool',
                priority: 'low',
                message: '',
                published: false
            },
            snackbar: false,
            snackbarMessage: ''
        }
    },
    mounted() {
        this.getMessage();
    },
    methods: {
        async getMessage() {
            try {
                const response = await messagesApi.get({ type: this.selectedType });
                const result = await response.json();
                if (!result) return;
                this.message = result;
                if (!this.message.type) this.message.type = this.selectedType;
            } catch (error) {
                console.log(error);
            }
        },
        handleSave() {
            if (this.message._id) this.updateMessage();
            else this.saveMessage();
        },
        async saveMessage() {
            try {
                await messagesApi.create(this.message);
                this.snackbar = true;
                this.snackbarMessage = 'Opgeslagen!';
                this.getMessage();
            } catch (error) {
                console.log(error);
            }
        },
        async updateMessage() {
            try {
                await messagesApi.update(this.message);
                this.snackbar = true;
                this.snackbarMessage = 'Opgeslagen!';
                this.getMessage();
            } catch (error) {
                console.log(error);
            }
        }
    },
}
</script>
