<template>
    <div>
        <v-row>
            <v-col>
                <DogsurvivalDetailsHeader 
                    @ticket-page="ticketPage = true"
                    @generate-start-number="handleGenerateStartNumber"
                    @show-setup="showSetup = true"
                    @add-participant="showAddParticipant = true"
                    @close="$emit('close')"
                    :participants="participants"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-left">#</th>
                            <th class="text-left">Naam</th>
                            <th class="text-left">E-mail</th>
                            <th class="text-left cursor-pointer" @click="sortForSpeed = !sortForSpeed">Snelheid</th>
                            <th class="text-left">Team</th>
                            <th class="text-left">Hond</th>
                            <th class="text-left">Betaald</th>
                            <th class="text-left">Starttijd</th>
                            <th class="text-left">Startnummer</th>
                            <th class="text-left">Acties</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(participant, i) in participants" :key="participant._id">
                            <td>{{ i+1 }}</td>
                            <td>{{ participant.name }}</td>
                            <td>{{ participant.email }}</td>
                            <td>{{ translateSpeed(participant.speed) }}</td>
                            <td>{{ participant.team }}</td>
                            <td>
                                <p class="m-0">{{ participant.dog.name }}</p>
                                <p class="m-0 text--disabled">{{ participant.dog.breed }} ({{ participant.dog.age }})</p>
                            </td>
                            <td>
                                <v-icon
                                    color="green"
                                    dark
                                    v-if="participant.paymentRef || participant.paid"
                                    >
                                    mdi-check
                                </v-icon>
                                <v-icon 
                                    color="red" 
                                    dark
                                    v-else
                                    >
                                    mdi-close
                                </v-icon>
                            </td>
                            <td>
                                <v-select
                                    :items="startTimeOptions"
                                    v-model="participant.startTime"
                                    no-data-text="Nog geen starttijd ingesteld"
                                    @change="handleSelectStartTime($event, participant._id)"
                                    dense
                                ></v-select>
                            </td>
                            <td class="text-center">
                                <strong>{{ participant.startNumber }}</strong>
                            </td>
                            <td>
                                <v-btn icon @click="editParticipant(participant)">
                                    <v-icon dark>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn icon @click="deleteParticipant(participant)">
                                    <v-icon dark>mdi-delete</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>

        <DogSurvivalDetailsSetup 
            :showSetup="showSetup"
            @close="handleClose"
            :startTime="startTime"
            @update:start-time="startTime = $event" 
            :interval="interval"
            @update:interval="interval = $event"
        />

        <DogSurvivalParticipantForm 
            v-model="showAddParticipant"
            @close="handleCloseParticipantDialog"
            @saved="$emit('refresh')"
            :selectedParticipant="selectedParticipant"
            :dogSurvivalId="selectedSurvival._id"
        />

        <DogSurvivalCardGenerator
            :dialog="ticketPage"
            :dogSurvival="selectedSurvival"
            :participants="participants"
            @close="ticketPage = false"
        />
    </div>
</template>

<script>
import DogSurvivalDetailsSetup from './details/DogSurvivalDetailsSetup.vue';
import DogSurvivalParticipantForm from './details/DogSurvivalParticipantForm.vue';
import DogSurvivalCardGenerator from './details/DogSurvivalCardGenerator.vue';
import DogsurvivalDetailsHeader from './details/DogSurvivalDetailsHeader.vue';

import dogsurvivalApi from '@/api/dogsurvival';

export default {
    components: {
        DogSurvivalDetailsSetup,
        DogSurvivalParticipantForm,
        DogSurvivalCardGenerator,
        DogsurvivalDetailsHeader,
    },
    props: {
        selectedSurvival: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    data() {
        return {
            showSetup: false,
            showAddParticipant: false,
            startTime: '',
            interval: 2,
            startTimeOptions: [],
            selectedParticipant: {},
            ticketPage: false,
            sortForSpeed: false,
        }
    },
    computed: {
        participants() {
            const participants = [ ...this.selectedSurvival.participants ];
            participants.sort((a, b) => {
                if (a.startTime && b.startTime) {
                    return a.startTime.localeCompare(b.startTime);
                } else if (a.startTime) {
                    return -1;
                } else if (b.startTime) {
                    return 1;
                } else {
                    return 0;
                }
            });

            if (this.sortForSpeed) {
                participants.sort((a, b) => {
                    if (a.speed === b.speed) {
                        return 0;
                    } else if (a.speed === 'walking') {
                        return -1;
                    } else if (b.speed === 'walking') {
                        return 1;
                    } else if (a.speed === 'combination') {
                        return -1;
                    } else if (b.speed === 'combination') {
                        return 1;
                    } else if (a.speed === 'running') {
                        return -1;
                    } else if (b.speed === 'running') {
                        return 1;
                    }
                });
            }

            return participants;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.setStartTime();
            this.generateStartTimeOptions();
            this.findInterval();
        },
        setStartTime() {
            let lowestTime = "";
            for (let participant of this.selectedSurvival.participants) {
                if (participant.startTime && (lowestTime === "" || participant.startTime < lowestTime)) {
                    lowestTime = participant.startTime;
                }
            }

            this.startTime = lowestTime;
        },
        findInterval() {
            if (this.startTimeOptions.length < 3) return;
            
            const firstTime = this.startTimeOptions[1].split(':');
            const secondTime = this.startTimeOptions[2].split(':');

            this.interval = +secondTime[1] - +firstTime[1];
        },
        generateStartTimeOptions() {
            let options = [];

            // Collect the currently set start times
            this.selectedSurvival.participants.forEach(participant => {
                if (!participant.startTime) return;

                if (!options.includes(participant.startTime)) {
                    options.push(participant.startTime);
                }
            });

            // Check if the current starttime is included in this list
            if (this.startTime && !options.includes(this.startTime)) {
                options.push(this.startTime);
            }

            // Sort the options
            options.sort((a, b) => a.localeCompare(b));
            
            // Add empty option
            options = [{ text: "geen tijd", value: "" }, ...options];
            this.startTimeOptions = options;
            this.addNextStartTime(options.at(-1));
        },
        addNextStartTime(e) {
            if (e.value === "") return;
            const [hours, minutes] = e.split(":").map(Number);
            const totalMinutes = hours * 60 + minutes + 2;
            const newHours = Math.floor(totalMinutes / 60) % 24;
            const newMinutes = totalMinutes % 60;
            const newTime = `${newHours.toString().padStart(2, "0")}:${newMinutes.toString().padStart(2, "0")}`;
            this.startTimeOptions.push(newTime);
        },
        translateSpeed(speed) {
            switch (speed) {
                case 'walking':
                    return 'Wandelen';
                case 'combination':
                    return 'Combinatie';
                case 'running':
                    return 'Rennen';
                default:
                    return 'Onbekend';
            }
        },
        handleClose() {
            this.showSetup = false;
            this.generateStartTimeOptions();
        },
        handleCloseParticipantDialog() {
            this.selectedParticipant = {};
            this.showAddParticipant = false;
        },
        handleSelectStartTime(e, participantId) {
            const index = this.startTimeOptions.indexOf(e);
            if (index === this.startTimeOptions.length - 1) {
                this.addNextStartTime(e);
            }
            this.setStartTimeForParticipant(e, participantId);
        },
        async setStartTimeForParticipant(startTime, participantId) {
            try {
                await dogsurvivalApi.updateParticipant(this.selectedSurvival._id, participantId, { startTime });
                this.$emit('refresh');
            } catch (error) {
                console.log(error);
            }
        },
        async handleGenerateStartNumber() {
            const result = await this.$swal.fire({
                title: 'Startnummers genereren?',
                text: `Alles startnummers worden opnieuw gegenereerd. Weet je het zeker?`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Annuleren'
            });

            if (result.isConfirmed) {
                const startTimes = [...new Set(this.participants.map(x => x.startTime))];
                this.participants.forEach(async participant => {
                    participant.startNumber = startTimes.indexOf(participant.startTime) + 1;
                    await dogsurvivalApi.updateParticipant(this.selectedSurvival._id, participant._id, { startNumber: participant.startNumber });
                });
            }         
        },
        editParticipant(participant) {
            this.selectedParticipant = participant;
            this.showAddParticipant = true;
        },
        async deleteParticipant(participant) {
            const result = await this.$swal.fire({
                title: 'Weet je het zeker?',
                text: `De deelnemer ${participant.name} wordt verwijderd.`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Annuleren'
            });
            
            if (result.isConfirmed) {
                try {
                    await dogsurvivalApi.deleteParticipant(this.selectedSurvival._id, participant._id);
                    this.$emit('refresh');
                } catch (error) {
                    console.log(error);
                }
            }
        },
    },
    watch: {
        selectedSurvival: {
            handler() {
                this.init();
            }
        }
    }
}
</script>
