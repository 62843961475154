import Vue from 'vue';
import VueRouter from 'vue-router';
import Admin from '../views/AdminWrapper.vue';
import EventAdmin from '../components/Admin/EventAdmin.vue';
import UserAdmin from '../components/Admin/UserAdmin.vue';
import PaymentAdmin from '../components/Admin/PaymentAdmin.vue';
import Home from '../views/Home.vue';
import SubscriptionAdmin from '../components/Admin/SubscriptionAdmin.vue';
import DogschoolAdmin from '../components/Admin/DogschoolAdmin.vue';
import InvoiceAdmin from '../components/Admin/InvoiceAdmin.vue';
import Registered from '@/views/Registered.vue';
import LogsAdmin from '@/components/Admin/LogsAdmin.vue';
import MessagesAdmin from '@/components/Admin/MessagesAdmin.vue';
import DogSurvivalAdmin from '@/components/Admin/DogSurvivalAdmin.vue';

import { authGuard } from './guards/authGuard'; 

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/dogsurvival',
        name: 'dogsurvival',
        component: () => import('../views/DogSurvival.vue'),
    },
    {
        path: '/registered',
        name: 'registered',
        component: Registered
    },
    {
        path: '/events',
        name: 'events',
        component: () => import('../views/EventList.vue'),
    },
    {
		path: '/register/:eventId',
		name: 'register',
        component: () => import('../views/EventRegistration.vue'),
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/Profile.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/subscriptions',
        name: 'subscriptions',
        component: () => import('../views/Subscriptions.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/payment/:paymentId?',
        name: 'payment',
        component: () => import('../views/Payment.vue'),
    },
    {
        path: '/dogschool',
        name: 'dogschool',
        component: () => import('../views/DogSchool.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin,
        beforeEnter: authGuard,
        children: [
            {
                path: 'dogschooladmin',
                components: {
                    default: Admin,
                    admin: DogschoolAdmin
                },
                name: 'dogschooladmin'
            },
            {
                path: 'eventadmin',
                components: {
                    default: Admin,
                    admin: EventAdmin
                },
                name: 'eventadmin'
            },
            {
                path: 'useradmin',
                components: {
                    default: Admin,
                    admin: UserAdmin
                },
                name: 'useradmin'
            },
            {
                path: 'paymentadmin',
                components: {
                    default: Admin,
                    admin: PaymentAdmin
                },
                name: 'paymentadmin'
            },
            {
                path: 'subscriptionadmin',
                components: {
                    default: Admin,
                    admin: SubscriptionAdmin
                },
                name: 'subscriptionadmin'
            },
            {
                path: 'invoiceadmin',
                components: {
                    default: Admin,
                    admin: InvoiceAdmin
                },
                name: 'invoiceadmin'
            },
            {
                path: 'logs',
                components: {
                    default: Admin,
                    admin: LogsAdmin
                },
                name: 'logs'
            },
            {
                path: 'messagesadmin',
                components: {
                    default: Admin,
                    admin: MessagesAdmin
                },
                name: 'messagesadmin'
            },
            {
                path: 'dogsurvivaladmin',
                components: {
                    default: Admin,
                    admin: DogSurvivalAdmin
                },
                name: 'dogsurvivaladmin'
            }
        ]
    },
    {
        path: '*',
        name: 'notfound',
        redirect: '/',
    }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
