<template>
    <v-expansion-panel v-if="detailUser">
        <v-expansion-panel-header>
            Gebruikersdetails
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <ul>
                <li>Naam: <strong>{{ detailUser.firstName }} {{ detailUser.lastName }}</strong></li>
                <li>Email: <strong>{{ detailUser.email }}</strong></li>
                <li>Adres: <strong>{{ `${detailUser.street || ''} - ${detailUser.address || ''}` }}</strong></li>
                <li>Telefoonnummer: <strong>{{ detailUser.phone }}</strong></li>
                <li>Foto's toegelaten: <strong>{{ picturesAllowed }}</strong></li>
            </ul>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
    props: {
        detailUser: {
            type: Object,
            required: true,
        }
    },
    computed: {
        picturesAllowed() {
            if (!this.detailUser.pictures) return '';
            switch (this.detailUser.pictures) {
                case 'yes':
                    return `Foto's toegelaten.`;
                case 'no':
                    return `Geen foto's toegelaten.`;
                case 'onlydog':
                    return `Enkel van de hond.`;
                default:
                    return '';
            }
        },
    },
}
</script>
