var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Titel")]),_c('th',{staticClass:"text-left"},[_vm._v("Start")]),_c('th',{staticClass:"text-left"},[_vm._v("Duurtijd")]),_c('th',{staticClass:"text-left"},[_vm._v("Deelnemers")]),_c('th',{staticClass:"text-left"},[_vm._v("Acties")])])]),_c('tbody',_vm._l((_vm.lessons),function(lesson){return _c('tr',{key:lesson._id},[_c('td',[_vm._v(_vm._s(lesson.title))]),_c('td',[_vm._v(_vm._s(`${_vm.$formatDate(lesson.date)} - ${_vm.$formatTime(lesson.date)}`))]),_c('td',[_vm._v(_vm._s(lesson.duration)+" minuten")]),_c('td',[_c('v-btn',{attrs:{"text":"","color":lesson.people.length + lesson.registrations.length < lesson.maxPeople - 1 
                                        ? 'secondary' 
                                        : lesson.people.length + lesson.registrations.length === lesson.maxPeople ? 'error' : 'warning'},on:{"click":function($event){return _vm.handleShowBookings(lesson)}}},[_vm._v(" Toon deelnemers ("+_vm._s(lesson.people.length + lesson.registrations.length)+"/"+_vm._s(lesson.maxPeople)+") ")])],1),_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('edit-lesson', lesson)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('edit-lesson', { 
                                    ...lesson, 
                                    people: [],
                                    registrations: [],
                                    _id: null 
                                })}}},[_c('v-icon',[_vm._v("mdi-content-duplicate")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.confirmRemoveLesson(lesson)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)])}),0)]),_c('CustomPagination',{attrs:{"page":_vm.filters.page,"currentItems":_vm.lessons.length},on:{"page-changed":function($event){return _vm.$emit('page-changed', $event)}}})],1)],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.bookingDialog),callback:function ($$v) {_vm.bookingDialog=$$v},expression:"bookingDialog"}},[_c('LessonDetails',{attrs:{"selectedLesson":_vm.selectedLesson},on:{"close-dialog":_vm.closeDialog,"remove-register":function($event){return _vm.removeRegister($event)},"refresh":function($event){return _vm.$emit('refresh')}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }