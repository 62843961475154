<template>
    <div>
        <v-dialog
            v-model="isIE"
            max-width="700"
            persistent
        >
            <v-card>
                <v-card-title class="headline">U gebruikt Internet Explorer. </v-card-title>

                <v-card-text>
                    <p>
                        Deze website werkt niet met Internet Explorer, gebruik een echte browser zoals <a href="https://www.mozilla.org/nl/firefox/new/" target="_blank">Firefox</a> of <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a>.
                    </p>
                    <p>
                        Waarom wordt Internet Explorer niet ondersteund? Er zijn een aantal redenen daarvoor:
                        <ul>
                            <li>De laatste nieuwe versie is gelanceerd in 2013, dus de browser is stokoud.</li>
                            <li>Zelfs bij het lanceren ondersteunde deze browser niet de courante webstandaarden.</li>
                            <li>Deze browser bevat een heleboel half-correct geïmplementeerde features, de website moet helemaal herbouwd worden om alle bugs te omzeilen.</li>
                            <li>Het markaandeel van Internet Explorer is minder dan 2%, het extra werk dat ondersteunen vergt is het niet waard.</li>
                        </ul>
                    </p>
                    <p>U kunt onze website probleemloos bezoeken op eender welke moderne browser zoals Safari, Chrome, Edge of Firefox.</p>
                </v-card-text>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isIE: false
        }
    },
    methods: {
        IECheck() {
            const ua = window.navigator.userAgent;
            const isIE = /MSIE|Trident/.test(ua);

            if (isIE) this.isIE = true;
        }
    },
    created() {
        this.IECheck();
    }
}
</script>