<template>
    <div class="admin-container">
        <v-row justify="center">
            <v-col sm="12" class="default-header">
                <h1>Hondenschool beheren</h1>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm=12>
                <FiltersBase 
                    v-model="filters"
                    :filters="['date', 'future']"
                    @reset="filters = { future: true, page: 0 }"
                />
            </v-col>
        </v-row>
        
        <DogSchoolList 
            :lessons="lessons"
            @refresh="getLessons"
            @edit-lesson="handleEditLesson"
            @page-changed="filters.page = $event"
            :filters="filters"
        />

        <!-- FAB -->
        <v-speed-dial
            v-model="fab"
            direction="top"
            fab
            bottom
            right
            fixed
            open-on-hover
        >
            <template v-slot:activator>
                <v-btn v-model="fab" fab color="red darken-1" large dark>
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else>mdi-chevron-up</v-icon>
                </v-btn>
            </template>
            <v-btn @click="getLessons" fab color="red darken-1" small dark>
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn @click="lessonDialog = true" fab color="red darken-1" small dark>
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-speed-dial>

        <!-- ADD LESSON DIALOG -->
        <CreateLesson 
            :showModal="lessonDialog"
            v-model="lesson"
            @close-dialog="lessonDialog = false"
            @save-lesson="saveLesson($event)"
        ></CreateLesson>

        <v-snackbar v-model="snackbar">
            {{ snackbarText }}
        </v-snackbar>

    </div>
</template>

<script>
import CreateLesson from './dogschool/CreateLesson';
import DogSchoolList from './dogschool/DogSchoolList';
import FiltersBase from './components/FiltersBase.vue';

import lessonApi from '@/api/lessons';

const newLesson = {
    date: new Date().toISOString(),
    maxPeople: 6,
    duration: 45,
}

export default {
    components: {
        CreateLesson,
        DogSchoolList,
        FiltersBase,
    },
    data() {
        return {
            fab: false,
            lessons: [],
            lessonDialog: false,
            lesson: {
                ...newLesson
            },
            filters: {
                future: true,
                page: 0
            },
            snackbar: false,
            snackbarText: ''
        }
    },
    created() {
        if (this.$route.query.id) this.filters.id = this.$route.query.id;
        
        this.getLessons();
    },
    methods: {
        handleEditLesson(e) {
            this.lesson = { ...e };
            this.lessonDialog = true;
        },
        async getLessons() {
            try {
                const response = await lessonApi.getAll(this.filters);
                this.lessons = await response.json();
            } catch (error) {
                console.log(error);
            }
        },
        async saveLesson(options) {
            try {
                await lessonApi.saveLesson(this.lesson, options.notifyUsers);
                if (options.addOne === false) {
                    this.lesson = { ...newLesson };
                    this.lessonDialog = false;
                }
                this.snackbarText = 'Opgeslagen!';
                this.snackbar = true;
                this.getLessons();
            } catch (error) {
                console.log(error);
            }
        },
    },
    watch: {
        filters: {
            handler() {
                this.getLessons();
            },
            deep: true
        },
        lessonDialog: {
            handler(val) {
                if (!val) this.lesson = { ...newLesson };
            }
        }
    },
}
</script>
