<template>
    <v-dialog v-model="localValue" @click:outside="localValue = false" max-width="90vw" width="600px">
        <v-card>
            <v-card-title>Dogsurvival aanmaken</v-card-title> 
            <v-card-text>
                <v-form ref="dogsurvival-form" @submit.prevent>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="dogsurvival.title"
                                label="Titel"
                                required
                                :rules="validRule"
                            ></v-text-field>
                            <label>Beschrijving</label>
                            <vue-editor 
                                v-model="dogsurvival.description"
                                :editorToolbar="editorToolbar"
                            ></vue-editor>
                            <label class="mt-4">Preview</label>
                            <div class="p-4" style="border: 1px solid gray">
                                <div v-html="dogsurvival.description"></div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h5>Datum</h5>
                            <CustomDatePicker v-model="dogsurvival.date" required></CustomDatePicker>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Locatie"
                                v-model="dogsurvival.location"
                                required
                                :rules="validRule"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Prijs"
                                type="Number"
                                min="0"
                                step="0.01"
                                v-model="dogsurvival.price"
                                prefix="€"
                                required
                                :rules="validRule"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Maximum aantal deelnemers"
                                type="Number"
                                min="0"
                                step="1"
                                v-model="dogsurvival.maxParticipants"
                                required
                                :rules="validRule"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" type="submit" @click="handleSubmit">Opslaan</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import CustomDatePicker from '@/components/Generic/CustomDatePicker';
import dogsurvivalApi from '@/api/dogsurvival';

import { VueEditor } from "vue2-editor";

const newDogsurvival = {
    title: '',
    description: '',
    date: '',
    location: '',
    price: '',
    maxParticipants: '',
};

export default {
    components: {
        VueEditor,
        CustomDatePicker,
    },
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false,
        },
        selectedSurvival: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            editorToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["code-block"]
            ],
            dogsurvival: {
                ...newDogsurvival
            },
            showForm: false,
            validRule: [
                v => !!v || 'Dit veld is verplicht'
            ]
        }
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        async handleSubmit() {
            if (!this.$refs['dogsurvival-form'].validate()) return;
            await this.createDogsurvival();
            this.handleClose();
        },
        async createDogsurvival() {
            try {
                await dogsurvivalApi.createDogsurvival(this.dogsurvival);
            } catch (error) {
                console.log(error);
            }
        },
        handleClose() {
            this.$emit('close');
            this.dogsurvival = { ...newDogsurvival };
        }
    },
    watch: {
        selectedSurvival: {
            handler(newVal) {
                if (newVal) {
                    this.dogsurvival = { ...newVal };
                }
            }
        }
    }
}
</script>
