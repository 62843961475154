var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Naam ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Datum ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Prijzen ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Inschrijvingen ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Publiek ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Bewerken ")])])]),_c('tbody',_vm._l((_vm.events),function(event){return _c('tr',{key:event._id},[_c('td',[_vm._v(_vm._s(event.name))]),_c('td',[_vm._v(_vm._s(_vm.$formatDate(event.start)))]),_c('td',_vm._l((event.prices),function(price){return _c('p',{key:price.pricePer,staticClass:"m-0"},[_vm._v(" "+_vm._s(price.pricePer)+": €"+_vm._s(price.price)+" ")])}),0),_c('td',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.handleShowRegistrations(event)}}},[_vm._v(" Inschrijvingen ("+_vm._s(_vm.countRegistrations(event))+"/"+_vm._s(event.maxPeople)+") ")])],1),_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.togglePublic(event)}}},[(event.public)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1)],1),_c('td',{staticClass:"text-nowrap"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('edit-event', event)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('edit-event', {
                            ...event,
                            _id: undefined,
                            createdAt: undefined,
                            manualRegistrations: [],
                            registrations: [],
                            updatedAt: undefined,
                        })}}},[_c('v-icon',[_vm._v("mdi-content-duplicate")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleDelete(event)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)])}),0)]),_c('CustomPagination',{attrs:{"page":_vm.filters.page,"currentItems":_vm.events.length},on:{"page-changed":function($event){return _vm.$emit('page-changed', $event)}}}),_c('ShowEventRegistrations',{attrs:{"editEvent":_vm.editEvent,"showRegistrations":_vm.showRegistrations},on:{"close":function($event){_vm.showRegistrations = false},"refresh":function($event){return _vm.$emit('refresh')}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }