<template>
    <div class="d-flex justify-content-center align-items-center my-4">
        <v-icon class="mr-2" :class="page === 0 ? 'text--disabled' : 'cursor-pointer'" @click="changePage(-1)">mdi-arrow-left</v-icon>
        {{ page + 1 }}
        <v-icon class="ml-2" :class="currentItems < 49 ? 'text--disabled' : 'cursor-pointer'" @click="changePage(1)">mdi-arrow-right</v-icon>
    </div>
</template>

<script>
export default {
    props: {
        page: {
            type: Number,
            required: true
        },
        currentItems: {
            type: Number,
            required: true
        }
    },
    methods: {
        changePage(dir) {
            if (this.page === 0 && dir === -1) return;
            if (this.currentItems < 49 && dir === 1) return;
            this.$emit('page-changed', this.page + dir);
        }
    }
}
</script>
