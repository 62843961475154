<template>
    <div class="navbar" :class="drawer ? 'navbar--open' : 'navbar--closed'">
        <div 
            class="navbar__item" 
            @click="handleNav('profile')" 
            v-if="$auth.isAuthenticated"
            :class="checkRoute('profile') ? 'navbar__item--active' : ''"
        >
            <v-icon class="mr-4">mdi-account</v-icon>
            <div class="navbar-item__text">Profiel</div>
        </div>
        
        <div 
            class="navbar__item"
            @click="handleNav('dogschool')" 
            v-if="$auth.isAuthenticated"
            :class="checkRoute('dogschool') ? 'navbar__item--active' : ''"
        >
            <v-icon class="mr-4">mdi-dog</v-icon>
            <div class="navbar-item__text">Hondenschool</div>
        </div>

        <div 
            class="navbar__item" 
            @click="handleNav('events')"
            :class="checkRoute('events') ? 'navbar__item--active' : ''"
        >
            <v-icon class="mr-4">mdi-calendar</v-icon>
            <div class="navbar-item__text">Events</div>
        </div>

        <div 
            class="navbar__item" 
            @click="handleNav('subscriptions')" 
            v-if="$auth.isAuthenticated"
            :class="checkRoute('subscriptions') ? 'navbar__item--active' : ''"
        >
            <v-icon class="mr-4">mdi-ticket</v-icon>
            <div class="navbar-item__text">Abonnement</div>
        </div>

        <v-divider v-if="admin"></v-divider>

        <v-subheader v-if="admin" class="navbar-item__text">
            Administratie
        </v-subheader>

        <div 
            class="navbar__item" 
            @click="handleNav('dogschooladmin')" 
            v-if="admin"
            :class="checkRoute('dogschooladmin') ? 'navbar__item--active' : ''"
        >
            <v-icon class="mr-4">mdi-dog</v-icon>
            <div class="navbar-item__text">Hondenschool</div>
        </div>

        <div 
            class="navbar__item" 
            @click="handleNav('eventadmin')" 
            v-if="admin"
            :class="checkRoute('eventadmin') ? 'navbar__item--active' : ''"
        >
            <v-icon class="mr-4">mdi-calendar</v-icon>
            <div class="navbar-item__text">Events</div>
        </div>

        <div 
            class="navbar__item" 
            @click="handleNav('subscriptionadmin')" 
            v-if="admin"
            :class="checkRoute('subscriptionadmin') ? 'navbar__item--active' : ''"
        >
            <v-icon class="mr-4">mdi-ticket</v-icon>
            <div class="navbar-item__text">Abonnementen</div>
        </div>

        <div 
            class="navbar__item" 
            @click="handleNav('dogsurvivaladmin')" 
            v-if="admin"
            :class="checkRoute('dogsurvivaladmin') ? 'navbar__item--active' : ''"
        >
            <v-icon class="mr-4">mdi-dog-side</v-icon>
            <div class="navbar-item__text">Dogsurvival</div>
        </div>

        <v-divider v-if="admin"></v-divider>

        <div 
            class="navbar__item" 
            @click="handleNav('useradmin')" 
            v-if="admin"
            :class="checkRoute('useradmin') ? 'navbar__item--active' : ''"
        >
            <v-icon class="mr-4">mdi-account-group</v-icon>
            <div class="navbar-item__text">Gebruikers</div>
        </div>

        <div 
            class="navbar__item" 
            @click="handleNav('paymentadmin')" 
            v-if="admin"
            :class="checkRoute('paymentadmin') ? 'navbar__item--active' : ''"
        >
            <v-icon class="mr-4">mdi-circle-multiple-outline</v-icon>
            <div class="navbar-item__text">Betalingen</div>
        </div>

        <div 
            class="navbar__item" 
            @click="handleNav('invoiceadmin')" 
            v-if="admin"
            :class="checkRoute('invoiceadmin') ? 'navbar__item--active' : ''"
        >
            <v-icon class="mr-4">mdi-receipt</v-icon>
            <div class="navbar-item__text">Facturatie</div>
        </div>
        
        <div 
            class="navbar__item" 
            @click="handleNav('messagesadmin')" 
            v-if="admin"
            :class="checkRoute('messagesadmin') ? 'navbar__item--active' : ''"
        >
            <v-icon class="mr-4">mdi-message</v-icon>
            <div class="navbar-item__text">Berichten</div>
        </div>  

        <v-divider v-if="admin"></v-divider>

        <div 
            class="navbar__item" 
            @click="handleNav('logs')" 
            v-if="admin"
            :class="checkRoute('logs') ? 'navbar__item--active' : ''"
        >
            <v-icon class="mr-4">mdi-note-text</v-icon>
            <div class="navbar-item__text">Logs</div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import * as types from '@/store/types';

export default {
    data() {
        return {
            showDrawer: false
        }
    },
    computed: {
        ...mapState({
            auth: state => state.auth,
            drawer: state => state.drawer
        }),
        admin() {
            return this.auth.administrator;
        }
    },
    methods: {
        handleNav(name) {
            this.$store.dispatch(types.SET_DRAWER, false);
            if (this.$route.name === name) return; 
            this.$router.push({ name: name });
        },
        checkRoute(route) {
            return this.$route.name === route;
        },
    },
    watch: {
        drawer() {
            this.showDrawer = this.drawer;
        },
        showDrawer() {
            if (!this.showDrawer) this.$emit('close-drawer');
        },
        
    }
}
</script>

<style>

</style>