<template>
	<v-card>
        <v-card-title>
            Voeg event toe
        </v-card-title>
        <v-card-text>
            <form @submit.prevent="submitForm">
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Naam"
                            v-model="event.name"
                            required
                        ></v-text-field>
                        <label>Beschrijving</label>
                        <vue-editor 
                            v-model="event.description"
                            :editorToolbar="editorToolbar"
                        ></vue-editor>
                        <label class="mt-4">Preview</label>
                        <div class="p-4" style="border: 1px solid gray">
                            <div v-html="event.description"></div>
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <h5>Startdatum en -tijd</h5>
                        <CustomDatePicker v-model="event.start"></CustomDatePicker>
                        <CustomTimePicker v-model="startTime"></CustomTimePicker>

                        <h5>Einddatum en -tijd</h5>
                        <CustomDatePicker v-model="event.end" :minDate="event.start"></CustomDatePicker>
                        <CustomTimePicker v-model="endTime"></CustomTimePicker>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols=6>
                        <v-text-field
                            label="Maximaal aantal deelnemers"
                            type="number"
                            min="0"
                            v-model="event.maxPeople"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols=6>
                        <v-text-field
                            label="Link"
                            v-model="event.url"
                            hint="Volledige link (beginnend met 'http://' of 'https://')"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <h5>Prijzen</h5>
                        <div class="d-flex align-items-center" v-for="(price, i) in event.prices" :key="i">
                            <v-text-field
                                label="Prijs"
                                type="Number"
                                min=0
                                v-model="event.prices[i].price"
                                prefix="€"
                                class="mr-2"
                            ></v-text-field>
                             <v-text-field
                                label="Eenheid van prijs"
                                v-model="event.prices[i].pricePer"
                                hint="Vermeld ook wat inbegrepen is (vb: 'inclusief huur sup')"
                                class="mr-2"
                            ></v-text-field>
                            <v-btn color="error" small @click="removePrice(i)" >
                                <v-icon small>mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col>
                        <v-btn small @click="addPrice">+ Voeg een prijs toe</v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols=12 md="6">
                        <v-text-field
                            label="Locatie"
                            v-model="event.location"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols=12 md="6">
                        <v-text-field
                            label="Plus code"
                            v-model="event.plusCode"
                        ></v-text-field>
                        <p class="m-0 text-caption">
                                Google Maps plus code. 
                                <a href="https://maps.google.com/pluscodes/" target="_blank">
                                    (?)
                                </a>
                            </p>
                    </v-col>
                </v-row>

                 <v-row>
                    <v-col>
                        <h4>Gasten toevoegen</h4>
                        <div v-for="(reg, i) in event.manualRegistrations" :key="i">
                            <v-btn
                                icon
                                @click="removeGuest(i)"
                                >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                            <span>{{ reg }}</span>
                        </div>
                        <div class="d-flex align-items-center">
                            <v-text-field
                                label="Gast toevoegen"
                                v-model="newGuest"
                                @keydown.tab.prevent="addGuest"
                            ></v-text-field>
                            <v-btn
                                icon
                                @click="addGuest"
                                >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-switch
                            v-model="addOne"
                            label="Nog een event toevoegen"
                        ></v-switch>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <div class="my-2">
                            <v-btn large color="primary" type="submit">
                                {{ event._id ? 'Bewerken' : 'Toevoegen' }}
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </form>
        </v-card-text>
	</v-card>
</template>

<script>
import CustomDatePicker from '@/components/Generic/CustomDatePicker';
import CustomTimePicker from '@/components/Generic/CustomTimePicker';
import { VueEditor } from "vue2-editor";

import eventApi from '@/api/events';

export default {
    props: {
        editEvent: {
            type: Object,
            required: false,
            default: null
        }
    },
    components: {
        CustomDatePicker,
        CustomTimePicker,
        VueEditor
    },
	data() {
		return {
            editorToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["code-block"]
            ],
            event: {},
            specialEventOptions: [
                { text: 'Geen', value: '' },
                { text: 'Dogsurvival', value: 'dogsurvival' }
            ],
            startTime: '',
            endTime: '',
            addOne: false,
            newGuest: ''
		}
	},
    created() {
        this.init();
    },
	methods: {
        init() {
            if (!this.editEvent) {
                this.event = {
                    manualRegistrations: []
                };
                this.startTime = '';
                this.endTime = '';
            } else {
                this.event = { 
                    manualRegistrations: [],
                    ...this.editEvent 
                };

                if (this.editEvent._id) {
                    this.startTime = this.$formatTime(this.editEvent.start);
                    this.endTime = this.$formatTime(this.editEvent.end);
                }
            }
        },
		async submitForm() {   
            if (this.event._id) await this.updateEvent();
            else await this.createEvent();

            if (!this.addOne) this.$emit('submitted');
            else {
                this.event._id = null;
                this.$emit('refresh');
            }

            this.addOne = false;
            this.init();
        },
        async updateEvent() {
            const body = this.createBody();

			try {
                await eventApi.updateEvent(body);
			} catch (error) {
				console.log(error);
			}
        },
        async createEvent() {
            const body = this.createBody();
            
			try {
                await eventApi.createEvent(body);
			} catch (error) {
				console.log(error);
			}
        },
        createBody() {
            const startTime = this.startTime.split(':');
            const endTime = this.endTime.split(':');
            return {
                ...this.event,
                start: new Date(this.event.start).setHours(startTime[0], startTime[1]),
                end: new Date(this.event.end).setHours(endTime[0], endTime[1])
            }
        },
        addPrice() {
            if (!this.event.prices) this.event.prices = [];
            this.event.prices.push({ price: 0, pricePer: 'per persoon' });

            this.$forceUpdate();
        },
        removePrice(i) {
            this.event.prices.splice(i, 1);

            this.$forceUpdate();
        },
        addGuest() {
            this.event.manualRegistrations.push(this.newGuest);
            this.newGuest = '';
        },
        removeGuest(i) {
            this.event.manualRegistrations.splice(i, 1);
        }
    },
    watch: {
        editEvent: {
            handler() {
                this.init();
            }
        },
    }
}
</script>
