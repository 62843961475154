<template>
    <v-card>
        <v-card-text id="printInvoice">
            <div class="container">
                <hr>
                <div class="row">
                    <template v-if="invoice.company === 'therapy4dogs'">
                        <div class="col-4">
                            <div class="mt-4">
                                Therapy4dogs<br>
                                Rouwleegd 29<br>
                                2340 Beerse<br>
                                info@therapy4dogs.be<br>
                                0564.721.231
                            </div>
                            
                        </div>
                        <div class="col-8">
                            <img class="img-fluid" src="../../../assets/img/header-image.jpeg" />
                        </div>
                    </template>
                    <template v-else-if="invoice.company === 'svbits'">
                        <div class="col-4">
                            <div class="mt-4">
                                SvBits<br>
                                Rouwleegd 29<br>
                                2340 Beerse<br>
                                stijn@svbits.be<br>
                                0564.721.231
                            </div>
                            
                        </div>
                        <div class="col-4"></div>
                        <div class="col-4">
                            <img class="img-fluid" src="../../../assets/img/svBits.png" />
                        </div>
                    </template>
                </div>
                <br>
                <br>
                <div class="row">
                    <div class="col-12">
                        <h1 class="mt-6">Factuur {{ invoice.invoiceNumber }}</h1>
                        <h2 class="mt-4">Opgemaakt op {{ new Date(invoice.createdAt).toLocaleDateString('nl-BE') }}</h2>
                    </div>
                </div>
                <br>
                <br>
                <br>
                <div class="row">
                    <div class="col-8">
                        <h2>Klantnaam</h2>
                        {{ invoice.client.name }}<br>
                        {{ invoice.client.street }}<br>
                        {{ invoice.client.address }}<br>
                        {{ invoice.client.vat }}
                    </div>
                    <div class="col-4">
                        <h2>Factuurnummer</h2>
                        {{ invoice.invoiceNumber }}
                        <br><br>
                        <h2>Te betalen voor</h2>
                        {{ new Date(invoice.due).toLocaleDateString('nl-BE') }}
                    </div>
                </div>
                <br>
                <br>
                <div class="row">
                    <div class="col-12">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <td>Beschrijving</td>
                                    <td>Prijs excl</td>
                                    <td>Hoeveelheid</td>
                                    <td>BTW</td>
                                    <td>BTW bedrag</td>
                                    <td>Totaal</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="line in invoice.lines" :key="line._id">
                                    <td>
                                        {{ line.description }}<br>
                                        <p style="font-size: .7rem;" v-if="line.comment !== ''">Nota: {{ line.comment }}</p>
                                    </td>
                                    <td>€{{ line.pricePer.toFixed(2).replace('.', ',') }}</td>
                                    <td>{{ line.amount }}</td>
                                    <td>{{ line.tax }}%</td>
                                    <td>€{{ calculateTax(line.pricePer, line.tax, line.amount).replace('.', ',') }}</td>
                                    <td>€{{ calculateTotal(line.amount, line.priceIn).replace('.', ',') }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <br><br>
                        <p>{{ invoice.comment }}</p>
                    </div>
                </div>
                <br>
                <br>
                <br>
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-end mr-4">
                            <h2>Totaal: €{{ total }}</h2>
                        </div>
                    </div>
                </div>
                <br>
                <br>
                <br>
                <div class="row">
                    <div class="col-12">
                        <p>Te betalen op rekeningnummer <strong>BE46 7333 1421 2736</strong> met de factuurnummer (<strong>{{ invoice.invoiceNumber }}</strong>) als vermelding.</p>
                    </div>
                </div>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-btn color="primary" @click="startPrint">Printen</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="$emit('close')">Sluiten</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: ['invoice'],
    data() {
        return {
            total: 0
        }
    },
    methods: {
        startPrint() {
            this.$htmlToPaper('printInvoice');
        },
        createTotals() {
            this.invoice.lines.forEach(line => {
                line.total = this.calculateTotal(line.amount, line.priceIn);
            });
        },
        calculateTotal(amount, price) {
            return (amount * price).toFixed(2);
        },
        calculateTax(price, tax, amount) {
            return (price * +`0.${tax}` * amount).toFixed(2);
        },
        calculateGeneralTotal() {
            let total = 0;

            this.invoice.lines.forEach(line => {
                total += +line.total;
            });
            
            this.total = total.toFixed(2).replace('.', ',');
        }
    },
    created() {
        this.createTotals();
    },
    mounted() {
        this.calculateGeneralTotal();
    }
}
</script>

<style lang="scss" scoped>
@import url('../../../assets/scss/bootstrap/bootstrap.min.css');
</style>