import * as http from './main';

const api = {
    get: (query) => {
        return http.get('messages', query);
    },

    create: (message) => {
        return http.post('messages', message);
    },

    update: (message) => {
        return http.put('messages', message);
    }
}

export default api;