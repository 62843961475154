const generatePlusCodeLink = (plusCode) => {
    // The base URL for Google Maps links.
    const url = `https://www.google.com/maps?q=${encodeURIComponent(plusCode)}&t=k`;
    return url;
}

const plusCode = {
    install: (Vue) => {
        Vue.prototype.$generatePlusCodeLink = (plusCode) => generatePlusCodeLink(plusCode);
    }
}

export default plusCode;