<template>
    <div>
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="value ? $formatDate(value) : ''"
                    :label="label"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="date"
                no-title
                :min="minDate"
                scrollable
            >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu = false"
            >
                Annuleren
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(date)"
            >
                OK
            </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true,
            default: ''
        },
        label: {
            type: String,
            required: false,
            default: 'Kies een datum'
        },
        minDate: {
            type: [String, Date],
            required: false,
            default: ''
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            menu: false,
            date: this.value
        }
    },
    watch: {
        date: {
            handler(val) {
                this.$emit('input', val);
            }
        }
    }
}
</script>
