<template>
    <div>
        <v-expansion-panels>
            <v-expansion-panel>
                <!-- Show the active filters here -->
                <v-expansion-panel-header>
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center m-0">
                            Filters
                            <div class="text-left ml-4">
                                <span v-for="(value, key) in value" :key="key">
                                    <v-chip v-if="value && value !== ''" color="primary" class="ml-2">
                                        {{ $translateFilter(key) }}
                                    </v-chip>
                                </span>
                            </div>
                        </div>
                        <v-btn icon @click.stop="resetFilters" class="mr-4" color="error" small>
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </div>
                </v-expansion-panel-header>

                <!-- Select the available filters here -->
                <v-expansion-panel-content>

                    <!-- DATE -->
                    <v-dialog
                        v-if="filters.includes('date')"
                        ref="datepicker"
                        v-model="dateModal"
                        :return-value.sync="value.date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="value.date ? $formatDate(value.date) : ''"
                                label="Zoeken op datum"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="value.date"
                            scrollable
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="dateModal = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.datepicker.save(value.date)"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>

                    <!-- FUTURE -->
                    <v-switch
                        v-if="filters.includes('future')"
                        v-model="value.future"
                        label="Toon enkel in de toekomst"
                    ></v-switch>

                    <!-- FIRST NAME -->
                    <v-text-field
                        v-if="filters.includes('firstName')"
                        label="Zoeken op voornaam"
                        v-model="value.firstName"
                    ></v-text-field>

                    <!-- LAST NAME -->
                    <v-text-field
                        v-if="filters.includes('lastName')"
                        label="Zoeken op achternaam"
                        v-model="value.lastName"
                    ></v-text-field>

                    <!-- EMAIL -->
                    <v-text-field
                        v-if="filters.includes('email')"
                        label="Zoeken op email"
                        v-model="value.email"
                    ></v-text-field>

                    <!-- PHOTOS -->
                    <v-select
                        v-if="filters.includes('photos')"
                        :items="photos"
                        label="Zoeken op toelating foto's"
                        v-model="value.photos"
                    ></v-select>

                    <!-- UNPAID -->
                    <v-switch
                        v-if="filters.includes('unpaid')"
                        v-model="value.unpaid"
                        label="Toon enkel onbetaald"
                    ></v-switch>

                    <!-- INVOICENUMBER -->
                    <v-text-field
                        v-if="filters.includes('invoiceNumber')"
                        label="Zoeken op factuurnummer"
                        v-model="value.invoiceNumber"
                        clearable
                    ></v-text-field>

                    <!-- LOGTYPES -->
                    <v-select
                        v-if="filters.includes('logType')"
                        :items="logTypes"
                        label="Zoeken op type log"
                        v-model="value.logType"
                    ></v-select>

                    <!-- MOLLIE ID -->
                    <v-text-field
                        v-if="filters.includes('mollie_id')"
                        label="Zoeken op ID"
                        v-model="value.mollie_id"
                        clearable
                    ></v-text-field>

                    <!-- ID -->
                    <v-text-field
                        v-if="filters.includes('id')"
                        label="Zoeken op ID"
                        v-model="value.id"
                        clearable
                    ></v-text-field>

                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        filters: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            dateModal: false,
            photos: [
                { text: 'Alle opties', value: '' },
                { text: 'Toegelaten', value: 'yes' },
                { text: 'Enkel hond', value: 'onlydog' },
                { text: `Geen foto's`, value: 'no' }
            ],
            logTypes: [
                { value: 'register-event', text: 'Geregistreerd voor event' }, 
                { value: 'register-lesson', text: 'Ingeschreven voor les' },
                { value: 'unregister-lesson', text: 'Uitgeschreven voor les' },
                { value: 'payment-received', text: 'Betaling ontvangen' },
                { value: 'user-created', text: 'Nieuwe gebruiker' },
                { value: 'presence-added', text: 'Beurtenkaart geknipt' },
                { value: 'reminder-mail-sent', text: 'Herinneringsmail abonnement verstuurd' },
            ],
        }
    },
    methods: {
        resetFilters() {
            this.$emit('reset');
        }
    }
}
</script>
