<template>
    <div>
        <v-simple-table>
            <thead>
                <tr>
                    <th class="text-left">
                        Naam
                    </th>
                    <th class="text-left">
                        Datum
                    </th>
                    <th class="text-left">
                        Prijzen
                    </th>
                    <th class="text-left">
                        Inschrijvingen
                    </th>
                    <th class="text-left">
                        Publiek
                    </th>
                    <th class="text-left">
                        Bewerken
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="event in events" :key="event._id">
                    <td>{{ event.name }}</td>
                    <td>{{ $formatDate(event.start) }}</td>
                    <td>
                        <p class="m-0" v-for="price in event.prices" :key="price.pricePer">
                            {{ price.pricePer }}: €{{ price.price }}
                        </p>
                        
                    </td>
                    <td>
                        <v-btn text color="primary" @click="handleShowRegistrations(event)">
                            Inschrijvingen ({{ countRegistrations(event) }}/{{ event.maxPeople }})
                        </v-btn>
                        
                    </td>
                    <td>
                        <v-btn icon @click="togglePublic(event)">
                            <v-icon v-if="event.public" color="green">mdi-check</v-icon>
                            <v-icon v-else color="red">mdi-close</v-icon>
                        </v-btn>
                    </td>
                    <td class="text-nowrap">
                        <v-btn icon @click="$emit('edit-event', event)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            @click="$emit('edit-event', {
                                ...event,
                                _id: undefined,
                                createdAt: undefined,
                                manualRegistrations: [],
                                registrations: [],
                                updatedAt: undefined,
                            })"
                            >
                            <v-icon>mdi-content-duplicate</v-icon>
                        </v-btn>
                        <v-btn icon @click="handleDelete(event)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <CustomPagination
            :page="filters.page"
            @page-changed="$emit('page-changed', $event)"
            :currentItems="events.length"
        />

        <ShowEventRegistrations 
            :editEvent="editEvent"
            :showRegistrations="showRegistrations"
            @close="showRegistrations = false"
            @refresh="$emit('refresh')"
        />
    </div>
</template>

<script>
import CustomPagination from '@/components/Generic/CustomPagination.vue';
import ShowEventRegistrations from '@/components/Admin/events/ShowEventRegistrations.vue';

import eventApi from '@/api/events';

export default {
    components: {
        CustomPagination,
        ShowEventRegistrations,
    },
    props: {
        editEvent: {
            type: Object,
            required: false,
        },
        filters: {
            type: Object,
            required: true,
        },
        events: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            showRegistrations: false,
            eventDialog: false,
        }
    },
    methods: {
        handleShowRegistrations(event) {
            this.$emit('set-event', event);
            this.showRegistrations = true;
        },
        setEditEvent(event) {
            this.$emit('set-event', event);
            this.eventDialog = true;
        },
        countRegistrations(event) {
            let numberOfRegistrations = event.manualRegistrations ? event.manualRegistrations.length : 0;
            numberOfRegistrations += event.registrations.length;
            event.registrations.forEach(reg => {
                if (!reg.guests) return;
                numberOfRegistrations = numberOfRegistrations + reg.guests.length;
            });
            return numberOfRegistrations;
        },
        async togglePublic(e) {
            try {
                const newEvent = {
                    ...e,
                    public: !e.public || false,
                };
                await eventApi.updateEvent(newEvent);
                this.$emit('refresh');
            } catch (error) {
                console.log(error);
            }
        },
        async handleDelete(event) {
            const result = await this.$swal.fire({
                title: 'Verwijderen?',
                text: `Dit event (${event.name}) wordt verwijderd.`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Annuleren'
            });

            if (result.isConfirmed) {
                try {
                    await eventApi.deleteEvent(event._id);
                    this.$emit('refresh');
                } catch (error) {
                    console.log(error);
                }
            }
        }
    },
}
</script>
