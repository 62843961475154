<template>
    <div>
        <v-row>
            <v-col>
                <h1>Geregisteerd!</h1>
                <p class="grey--text text--darken-2">
                    Je bent geregistreerd! We hebben nog een paar extra gegevens van je nodig.
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <form @submit.prevent="register">
                    <v-row>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                label="Voornaam"
                                v-model.trim="user.firstName"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                label="Achternaam"
                                v-model.trim="user.lastName"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="6">
                            <v-select
                                :items="pictureItems"
                                required
                                label="Ik geef toestemming foto's te nemen en publiceren van: "
                                v-model="user.pictures"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn color="primary" type="submit">Registreren</v-btn>
                        </v-col>
                    </v-row>
                </form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import userApi from '@/api/users.js';

export default {
    name: 'RegisteredComponent',
    data() {
        return {
            confirmed: false,
            user: {
                email: null,
                firstName: null,
                lastName: null,
                pictures: 'yes',
                auth_id: null
            },
            pictureItems: [
                {
                    text: 'NIET mijn hond en NIET mezelf',
                    value: 'no'
                },
                {
                    text: 'WEL mijn hond en NIET mezelf',
                    value: 'onlydog'
                },
                {
                    text: 'WEL mijn hond en WEL mezelf',
                    value: 'yes'
                }
            ],
        }
    },
    methods: {
        init() {
            const user = this.$auth.user;
            if (!user) this.$router.push('/');
            this.user.email = user.email;
            this.user.auth_id = user.sub;
        },
        async register() {
            try {
                await userApi.createUser(this.user);
                
                this.$router.push({ name: 'profile' });
            } catch (error) {
                this.error = true;
                console.log(error);
            }
        }
    },
    watch: {
        '$auth.loading': {
            handler(val) {
                if (val === false) this.init();
            }
        }
    }
}
</script>
