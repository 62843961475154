import * as http from './main';

const api = {
    getPayments: (query) => {
        return http.get('payments', query);
    },
    
    createPayment: (registration) => {
        return http.post('payments/create', registration);
    },

    deleteRegistration: (eventId, regId) => {
        return http.deleteCall(`payments?eventId=${eventId}&regId=${regId}`);
    }
}

export default api;