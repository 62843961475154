<template>
    <div>
        <v-row>
            <v-col sm="12">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-left">Titel</th>
                            <th class="text-left">Start</th>
                            <th class="text-left">Duurtijd</th>
                            <th class="text-left">Deelnemers</th>
                            <th class="text-left">Acties</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="lesson in lessons" :key="lesson._id">
                            <td>{{ lesson.title }}</td>
                            <td>{{ `${$formatDate(lesson.date)} - ${$formatTime(lesson.date)}` }}</td>
                            <td>{{ lesson.duration }} minuten</td>
                            <td>
                                <v-btn 
                                    text 
                                    @click="handleShowBookings(lesson)"
                                    :color="
                                        lesson.people.length + lesson.registrations.length < lesson.maxPeople - 1 
                                            ? 'secondary' 
                                            : lesson.people.length + lesson.registrations.length === lesson.maxPeople ? 'error' : 'warning'
                                        "
                                    >
                                    Toon deelnemers ({{ lesson.people.length + lesson.registrations.length }}/{{ lesson.maxPeople }})
                                </v-btn>
                            </td>
                            <td>
                                <v-btn
                                    icon
                                    @click="$emit('edit-lesson', lesson)"
                                    >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    @click="$emit('edit-lesson', { 
                                        ...lesson, 
                                        people: [],
                                        registrations: [],
                                        _id: null 
                                    })"
                                    >
                                    <v-icon>mdi-content-duplicate</v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    @click="confirmRemoveLesson(lesson)"
                                    >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <CustomPagination
                    :page="filters.page"
                    @page-changed="$emit('page-changed', $event)"
                    :currentItems="lessons.length"
                ></CustomPagination>
            </v-col>
        </v-row>

        <!-- DIALOG - registrations -->
        <v-dialog v-model="bookingDialog" max-width="600">
            <LessonDetails
                :selectedLesson="selectedLesson"
                @close-dialog="closeDialog"
                @remove-register="removeRegister($event)"
                @refresh="$emit('refresh')"
            ></LessonDetails>
        </v-dialog>
    </div>
</template>

<script>
import CustomPagination from '@/components/Generic/CustomPagination';
import LessonDetails from './LessonDetails';

import lessonApi from '@/api/lessons';

export default {
    components: {
        CustomPagination,
        LessonDetails
    },
    props: {
        lessons: {
            type: Array,
            required: false,
            default: () => []
        },
        filters: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    data() {
        return {
            selectedLesson: null,
            bookingDialog: false,
        }
    },
    methods: {
        closeDialog() {
            this.bookingDialog = false;
        },
        async confirmRemoveLesson(lesson) {
            const result = await this.$swal.fire({
                title: 'Verwijderen?',
                text: `Een les verwijderen kan niet ongedaan gemaakt worden!`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Annuleren'
            });

            if (result.value) this.removeLesson(lesson);
        },
        handleShowBookings(lesson) {
            this.selectedLesson = lesson;
            this.bookingDialog = true;
        },
        async removeLesson(lesson) {
            try {
                await lessonApi.deleteLesson(lesson._id);
                this.$emit('refresh');
            } catch (error) {
                console.log(error);
            }
        },
        async removeRegister(e) {
            try {
                await lessonApi.unregisterAdmin({ lessonId: e.lesson._id, personId: e.person._id });
                this.$emit('refresh');
                this.bookingDialog = false;
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>
